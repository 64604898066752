import { TransactionRequest } from "./transactions";

export enum TypeOfRequest {
	SearchByAdress = 1,
	OptionalSearch,
}

export enum TypeOfTableData {
	balancesTable = 1,
	transactionsTable,
	transactionDetailsTable,
}
export enum SubscriptionLevel {
	Free = 1,
	Paid,
}

export interface HandleRequestProps {
	handleClick: (type: TypeOfRequest, body: BlockchainDataModel) => void;
	browserTimeZone: string;
	timeZones: string[];
	listOfNetworks: NetworkModal[];
	itemHeight: (elementHeight: number) => void;
	formData: BlockchainDataModel;
	clearClick?: boolean;
	onFileUpload?: (form: TypeOfTable, isActive: boolean) => void;
}
export interface HandleRequestTransactionsProps {
	handleClickOnTransactions: (
		type: TypeOfRequest,
		body: TransactionRequest,
		isTransactionByHash: boolean
	) => void;
	browserTimeZone: string;
	timeZones: string[];
	listOfNetworks?: NetworkModal[];
	itemHeight: (elementHeight: number) => void;
	onFileUpload?: (form: TypeOfTable, isActive: boolean) => void;
}
export interface HandleDescriptionProps {
	listOfNetworks: NetworkModal[];
}

export interface BlockchainDataModel {
	walletAddress: string;
	dateTime?: string | Date | null;
	timeZone: string;
	blockNumber?: string;
	tokenContractAddresses?: string[] | null;
	networkId?: number | null | string;
	providerIds?: number[];
	searchCriteria?: SearchCriteria;
	requestQuerryParams: string;
	isLatestReq?: boolean;
	isCustomContractAddress?: boolean;
}

export interface BlockchainResponseModel {
	walletAddress?: string;
	coinBalance?: string;
	blockNumber?: number;
	dateTime?: string;
	dateTimeUtc?: string;
	tokenBalances?: TokenBalanceModel[];
}

export interface TokenBalanceModel {
	error: string | null;
	token: TokenModel[];
	tokenBalance: string;
}

export interface TokenModel {
	id?: number;
	name?: string;
	shortName?: string;
	iconPath?: string;
	contractAddress?: string;
	decimalPlaces?: number;
	networkId?: number;
	network?: string;
}

export interface TableDataModel {
	id: number;
	name: string | null;
	tokenImg: string;
	shortName: string | null;
	iconPath: string | null;
	contractAddress: string | null;
	decimalPlaces: number | null;
	networkId: number | null;
	blockNumber: number | null;
	dateTime: string | null | Date;
	dateTimeUtc: string | null | Date;
	walletAddress: string | null;
	balance: number | null;
	networkName: string;
	networkShortName: string;
	networkProviderName: string;
	networkProviderIconPath: string;
	unixTimeUTC: string | Date;
	humanTms: string | Date;
	unixTms: string | Date | number | null;
}

export interface NetworkModal {
	assetsNumber: number;
	iconPath: string;
	id: number;
	name: string;
	networkName: string;
	shortName: string;
	transactionHashExplorerUrlTemplate: null | string;
	walletAddressExplorerUrlTemplate: string;
	walletValidationRegex: string;
	protocolName: string;
}

export interface NetworkCoinToken {
	contractAddress: null | string;
	groupTag: null | string;
	iconPath: string;
	id: number;
	isNativeAsset: boolean;
	name: string;
	networkId: number;
	shortName: string;
}

export interface Asset {
	id: number;
	name: string;
	shortName: string;
	iconPath: string;
	contractAddress: string | null;
	decimalPlaces: number;
	networkId: number;
}

export interface NetworkCoinsResponse {
	data: NetworkModal[];
}
export interface NetworkCoinstProps {
	network: NetworkModal[];
	error: string;
}

export interface SingleNetworkCoinsProps {
	data: NetworkModal;
	url: string;
	explorerUrl: string;
}

interface Token extends NetworkCoinToken {
	decimalPlaces: number;
}

export interface NetworkProvider {
	id: number;
	name: string;
	iconPath: string;
}

export interface AssetsModel {
	asset: Asset;
	assetBalance: string;
	error: null | string;
}

export interface Network {
	id: number;
	name: string;
	coinId: number;
	coin: {
		id: number;
		name: string;
		shortName: string;
		iconPath: string;
	};
	hasTokens: boolean;
}

export interface ResponseData {
	walletAddress: string;
	networks: SingleNetwork[];
}

export interface SingleNetwork {
	network: Network;
	providerBalance: ProviderBalance;
	error: string | null;
}

export interface MetaTagsForSeo {
	title: string;
	description: string;
	type: string;
	url: string;
}

export enum TypeOfTable {
	Balances = 1,
	Transaction,
	CustomizedBalances,
	CustomizedTransactions,
	TransactionDetails,
}

export interface TransactionTableResponse {
	id: number;
	transactionHash: string;
	txType: string;
	dateTimeUtc: Date | string;
	from: string;
	to: string;
	amount: number;
	asset: string;
}

export interface SearchCriteria {
	coinAmount: number;
	tokensAmount: number;
}
export interface ProviderBalance {
	provider: NetworkProvider;
	blockNumber: number | null;
	dateTime: string | null;
	dateTimeUtc: string | null;
	assets: AssetsModel[] | null;
	error: string | null;
}
