import React, { useCallback, useEffect, useState } from "react";
import {
	TextField,
	Button,
	FormControl,
	Select,
	MenuItem,
	Grid,
	Stack,
	Typography,
	InputLabel,
	Box,
	FormHelperText,
	Autocomplete,
	Divider,
	OutlinedInput,
	useTheme,
} from "@mui/material";
import {
	LocalizationProvider,
	DateTimePicker,
	renderTimeViewClock,
} from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
	HandleRequestProps,
	NetworkModal,
	NetworkCoinToken,
	TypeOfRequest,
	TypeOfTableData,
	TypeOfTable,
	HandleRequestTransactionsProps,
} from "../../models/models";
import { api } from "../../utilities/index";
import { FixedSizeList } from "react-window";

export const SpecializedTransactionForm: React.FC<
	HandleRequestTransactionsProps
> = ({
	browserTimeZone,
	timeZones,
	listOfNetworks,
	onFileUpload,
	handleClickOnTransactions,
}) => {
	const [protocol, setProtocol] = useState("");
	const [protocolToken, setProtocolToken] = useState<string | null>(null);
	const [walletAddress, setWalletAdress] = useState("");
	const [startBlockNumber, setStartBlockNumber] = useState("");
	const [endBlockNumber, setEndBlockNumber] = useState("");
	const [error, setError] = useState(false);
	const [errorProtocol, setErrorProtocol] = useState(false);

	const [listOfTokens, setListOfTokens] = useState<NetworkCoinToken[]>([]);
	const [startDateAndTime, setStartDateAndTime] = useState<Dayjs | null>(null);
	const [endDateAndTime, setEndDateAndTime] = useState<Dayjs | null>(null);
	const [dateStartError, setStartDateError] = useState(false);
	const [dateEndError, setEndDateError] = useState(false);
	const [isListOpen, setListOpen] = useState(false);
	const [isEndTimeZoneListOpen, setEndTimeZoneLIst] = useState(false);
	const [startTimeZone, setStartTimeZone] = useState(browserTimeZone);
	const [endTimeZone, setEndTimeZone] = useState(browserTimeZone);

	const handleInputValue = (e: any) => {
		setWalletAdress(e.target.value);

		// if (e.target.value.length === 0) {
		// 	setError(true);
		// } else {
		// 	setError(false);
		// }
	};

	const handleFileUpload = () => {
		if (onFileUpload) {
			onFileUpload(TypeOfTable.CustomizedTransactions, false);
		}
	};
	const handleProtocolChange = (event: any) => {
		setProtocol(event.target.value);

		const selectedProtocol = listOfNetworks?.find((network: NetworkModal) => {
			return network.name === protocol;
		});
		const regex = new RegExp(selectedProtocol?.walletValidationRegex || "");

		// if (event.target.value.length === 0) {
		// 	setErrorProtocol(true);
		// } else {
		setErrorProtocol(false);
		const networkCoin = listOfNetworks?.find((item: NetworkModal) => {
			return item.name === event.target.value;
		});
		getTokensForNetwork(networkCoin?.id || 0);
		// }
	};

	const handleBlockNumber = (event: any) => {
		setStartDateAndTime(null);
		setEndDateAndTime(null);
		const value = event.target.value.replace(/\D/g, "");
		event.target.value = value;
		setStartBlockNumber(event.target.value);
	};
	const handleEndBlockNumber = (event: any) => {
		setStartDateAndTime(null);
		setEndDateAndTime(null);
		const value = event.target.value.replace(/\D/g, "");
		event.target.value = value;
		setEndBlockNumber(event.target.value);
	};
	const handleTokenChange = (e: any) => {
		setProtocolToken(e.target.value);
	};
	const onTokenNetworkChange = useCallback(
		(e: any, newValue: any) => {
			setProtocolToken(newValue);
		},
		[setProtocolToken]
	);

	const sendRequest = () => {
		// if (walletAddress.length === 0) {
		// 	setError(true);
		// }
		// if (protocol.length === 0) {
		// 	setErrorProtocol(true);
		// }

		// if (walletAddress.length !== 0 && protocol.length !== 0) {
		handleClickOnTransactions(
			TypeOfRequest.OptionalSearch,
			{
				walletAddress: walletAddress,
				startTimeZone: startTimeZone,
				endTimeZone: endTimeZone,
				endDate: endDateAndTime?.format(),
				startDate: startDateAndTime?.format(),
				pageNumber: 4,
			},
			false
		);
		// }
	};
	const getTokensForNetwork = async (id: number) => {
		try {
			const response = await api.get(`get-assets?NetworkId=${id}`, {
				headers: {
					"Cache-Control": "public, max-age=3600",
				},
			});
			const { data } = response;
			setListOfTokens(data.assets);
		} catch (error: any) {
			console.log(error);
		}
	};

	const handleStartTimeZoneChange = (value: string) => {
		setStartTimeZone(value);
		setListOpen(false);
	};
	const handleEndTimeZoneChange = (value: string) => {
		setEndTimeZone(value);
		setEndTimeZoneLIst(false);
	};
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: 200,
				width: 200,
				minWidth: 200,
			},
		},
	};

	const ITEM_HEIGHT = 48;
	const VISIBLE_ITEMS = 400;

	const totalHeight = Math.min(timeZones.length, VISIBLE_ITEMS) * ITEM_HEIGHT;

	const renderRow = useCallback(({ index, style }: any) => {
		return (
			<MenuItem
				key={index}
				value={startTimeZone[index]}
				style={style}
				onClick={() => handleStartTimeZoneChange(timeZones[index])}
			>
				{timeZones[index]}
			</MenuItem>
		);
		// eslint-disable-next-line
	}, []);
	const renderEndRow = useCallback(({ index, style }: any) => {
		return (
			<MenuItem
				key={index}
				value={endTimeZone[index]}
				style={style}
				onClick={() => handleEndTimeZoneChange(timeZones[index])}
			>
				{timeZones[index]}
			</MenuItem>
		);
		// eslint-disable-next-line
	}, []);
	return (
		<Typography
			component="article"
			mt={0}
			sx={{
				maxWidth: "500px",
				width: { sm: "100%", md: "45%" },
				order: { xs: 7, sm: 7, md: 6, lg: 6 },
			}}
		>
			<Divider
				variant="inset"
				sx={{
					margin: "2rem 0 4rem",
					display: { xs: "block", sm: "block", md: "none", lg: "none" },
				}}
			/>

			<Typography
				component="h3"
				variant="h1"
				gutterBottom
				marginBottom={2}
				fontFamily={"Ysabeau SC"}
				id="specialized-transactions-form"
			>
				Customized Transactions
			</Typography>
			<Box sx={{ maxWidth: "500px" }}>
				<Typography variant="subtitle2" gutterBottom>
					Use this form for the following cases:
				</Typography>
				<Typography variant="subtitle2" gutterBottom>
					Inquiry a network on a block number range; for all assets; or for
					a given token contract;
				</Typography>
				<Typography variant="subtitle2">
					Or, get transactions for a specific token; for a range of dates
					or blocks (start, end or between)
				</Typography>{" "}
			</Box>
			<Box
				sx={{
					padding: "16.5px 0 20px 0 ",
					maxWidth: "550px",
					marginTop: 0,
				}}
			>
				<form>
					<Stack spacing={3} maxWidth={500}>
						<FormControl
							error={errorProtocol}
							sx={{
								maxWidth: "100%",
								paddingBottom: errorProtocol ? 0 : "23px",
							}}
						>
							<InputLabel id="selectProtocol">
								Protocol Network *
							</InputLabel>
							<Select
								required
								label="Protocol Network *"
								labelId="address-hash-customized-transactions-form-label"
								id="Protocol Network"
								value={protocol}
								onChange={handleProtocolChange}
								onBlur={handleProtocolChange}
								error={errorProtocol}
								MenuProps={{
									MenuListProps: {
										style: { maxHeight: 290 },
									},
								}}
							>
								{listOfNetworks
									?.slice()
									.sort((a: NetworkModal, b: NetworkModal) => {
										return a.name.localeCompare(b.name);
									})
									.map((data: NetworkModal) => {
										return (
											<MenuItem
												value={data.name}
												key={data.name + `optional search`}
											>
												{data.name}
											</MenuItem>
										);
									})}
							</Select>
							{errorProtocol && (
								<FormHelperText sx={{ color: "#d32f2f" }}>
									The protocol network value is required.
								</FormHelperText>
							)}
						</FormControl>
						<TextField
							required
							autoComplete="off"
							id="address-hash-customized-transactions-form"
							label="Blockchain Wallet Address"
							variant="outlined"
							onChange={handleInputValue}
							onBlur={handleInputValue}
							error={error}
							value={walletAddress}
							helperText={
								error ? "The address value is required." : false
							}
							sx={{
								paddingBottom: !error ? "23px" : 0,
							}}
						/>
						<Grid
							sx={{
								display: "flex",
								flexDirection: { xs: "column", md: "row" },
								margin: 0,
								gap: 0.5,
							}}
						>
							<Typography variant="h5" gutterBottom margin={0}>
								Optional:
							</Typography>
							<Typography variant="h5" gutterBottom margin={0}>
								Block, Date Range, Time & Timezone
							</Typography>
						</Grid>
						<Grid
							container
							justifyContent="space-between"
							sx={{ display: "flex", gap: 2 }}
						>
							<Grid item xs={12} md={5.5}>
								{" "}
								<FormControl fullWidth>
									<TextField
										autoComplete="off"
										id="optional-start-block-number-customized-transaction"
										variant="outlined"
										label="Start Block Number"
										value={startBlockNumber}
										onChange={handleBlockNumber}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} md={5.5}>
								{" "}
								<FormControl fullWidth>
									<TextField
										autoComplete="off"
										id="outlined-basic"
										variant="outlined"
										label="End Block Number"
										value={endBlockNumber}
										onChange={handleEndBlockNumber}
									/>
								</FormControl>
							</Grid>
						</Grid>

						<Box>
							<Typography variant="subtitle2">
								Leave the date blank to get all the transactions (latest
								block).
								<br />
							</Typography>

							<Grid
								container
								justifyContent={"space-between"}
								rowSpacing={2}
								paddingTop={4}
							>
								<Grid
									item
									xs={12}
									md={6}
									key="date-input"
									sx={{ paddingBottom: dateStartError ? 0 : "23px" }}
								>
									<FormControl fullWidth>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DateTimePicker
												ampm={false}
												onError={(error) => {
													error === "invalidDate"
														? setStartDateError(true)
														: setStartDateError(false);
												}}
												slotProps={{
													textField: {
														helperText: dateStartError
															? " Please input a valid format."
															: false,
													},
												}}
												defaultValue={startDateAndTime}
												label="Select Start Date & Time"
												viewRenderers={{
													hours: renderTimeViewClock,
													minutes: renderTimeViewClock,
													seconds: renderTimeViewClock,
												}}
												onChange={(newValue: any) => {
													setStartBlockNumber("");
													setEndBlockNumber("");
													setStartDateAndTime(newValue);
												}}
											/>
										</LocalizationProvider>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={5} key="input-timeZone-start">
									<FormControl fullWidth>
										<InputLabel id="timeZone">
											Start Time Zone
										</InputLabel>
										<Select
											onClose={() => setListOpen(false)}
											open={isListOpen}
											onOpen={() => setListOpen(true)}
											displayEmpty
											input={
												<OutlinedInput label="Start Time Zone" />
											}
											value={startTimeZone}
											renderValue={(selected) =>
												selected ? selected : startTimeZone
											}
											MenuProps={{
												...MenuProps,
												anchorOrigin: {
													vertical: "bottom",
													horizontal: "left",
												},
												transformOrigin: {
													vertical: "top",
													horizontal: "left",
												},
											}}
										>
											<FixedSizeList
												height={totalHeight}
												width={250}
												itemSize={ITEM_HEIGHT}
												itemCount={timeZones.length}
											>
												{renderRow}
											</FixedSizeList>
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={12}
									md={6}
									key="date-input-end"
									sx={{ paddingBottom: dateEndError ? 0 : "23px" }}
								>
									<FormControl fullWidth>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DateTimePicker
												ampm={false}
												onError={(error) => {
													error === "invalidDate"
														? setEndDateError(true)
														: setEndDateError(false);
												}}
												slotProps={{
													textField: {
														helperText: dateEndError
															? "Please input a valid format."
															: false,
													},
												}}
												defaultValue={endDateAndTime}
												label="Select End Date & Time"
												viewRenderers={{
													hours: renderTimeViewClock,
													minutes: renderTimeViewClock,
													seconds: renderTimeViewClock,
												}}
												onChange={(newValue: any) => {
													setStartBlockNumber("");
													setEndBlockNumber("");
													setEndDateAndTime(newValue);
												}}
											/>
										</LocalizationProvider>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={5} key="input-timeZone-end">
									<FormControl fullWidth>
										<InputLabel id="endTimeZone">
											End Time Zone
										</InputLabel>
										<Select
											onClose={() => setEndTimeZoneLIst(false)}
											open={isEndTimeZoneListOpen}
											onOpen={() => setEndTimeZoneLIst(true)}
											displayEmpty
											input={<OutlinedInput label="End Time Zone" />}
											value={endTimeZone}
											renderValue={(selected) =>
												selected ? selected : endTimeZone
											}
											MenuProps={{
												...MenuProps,
												anchorOrigin: {
													vertical: "bottom",
													horizontal: "left",
												},
												transformOrigin: {
													vertical: "top",
													horizontal: "left",
												},
											}}
										>
											<FixedSizeList
												height={totalHeight}
												width={250}
												itemSize={ITEM_HEIGHT}
												itemCount={timeZones.length}
											>
												{renderEndRow}
											</FixedSizeList>
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Box>

						<>
							<Typography variant="h5">
								Optional: Asset Contract Address
							</Typography>

							<FormControl fullWidth>
								<Autocomplete
									options={listOfTokens
										.filter((asset: NetworkCoinToken) => {
											return asset.isNativeAsset === false;
										})
										.slice()
										.sort(
											(a: NetworkCoinToken, b: NetworkCoinToken) => {
												return a.name.localeCompare(b.name);
											}
										)
										.map((data: NetworkCoinToken) =>
											data.name ? data.name : ""
										)}
									freeSolo
									disabled={
										protocolToken?.length === 0
											? listOfTokens.filter(
													(asset: NetworkCoinToken) => {
														return asset.isNativeAsset === false;
													}
											  ).length === 0
												? true
												: false
											: false
									}
									value={protocolToken}
									onChange={onTokenNetworkChange}
									renderInput={(params) => (
										<TextField
											onChange={handleTokenChange}
											{...params}
											label="Input address or select from our list of assets for network"
											variant="outlined"
											fullWidth
											helperText={
												protocol.length === 0
													? "Please choose a protocol from the list of assets located at the top of the form."
													: listOfTokens.length === 0
													? "The selected protocol has no assets."
													: ""
											}
										/>
									)}
								/>
							</FormControl>
						</>
					</Stack>
				</form>
				<Grid
					sx={{
						display: "flex",
						flexDirection: { xs: "column", md: "row" },
						justifyContent: { xs: "center", md: "space-between" },
						alignItems: "baseline",
					}}
				>
					<Button
						variant="contained"
						onClick={sendRequest}
						size="large"
						sx={{
							fontSize: 13,
							marginTop: 2,

							width: "180px",
						}}
						// disabled={error || errorProtocol}
					>
						Get Transactions
					</Button>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							gap: 0.25,
						}}
					>
						{" "}
						<Button
							sx={{
								fontSize: 13,
								marginTop: 2,
								width: "180px",
							}}
							variant="contained"
							onClick={handleFileUpload}
							size="large"
						>
							Upload file
						</Button>{" "}
						<Typography variant="subtitle2">
							{" "}
							for batch transactions
						</Typography>
					</Box>
				</Grid>
			</Box>
		</Typography>
	);
};
