import React from "react";
import { AppBar, Toolbar, Typography, Box, Container } from "@mui/material";
import { Link } from "react-router-dom";

export const Footer = () => {
	return (
		<AppBar
			position="static"
			component="footer"
			sx={{
				top: "auto",
				bottom: 0,
				backgroundColor: (theme: any) => theme.palette.primary.main,
				color: (theme: any) => theme.palette.common.white,
				margin: 0,
			}}
		>
			<Container maxWidth="lg" disableGutters={true}>
				<Toolbar
					disableGutters={true}
					sx={{
						display: "flex",
						flexDirection: { xs: "column", md: "row" },
						justifyContent: "space-between",
						alignItems: "center",
						paddingY: "8px",
						paddingX: { xs: "24px", lg: 0 },
						gap: 2,
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: { xs: "center", lg: "baseline" },
							flexDirection: { xs: "column", md: "row" },
							gap: 2,
						}}
					>
						<Typography
							variant="body2"
							component="span"
							fontFamily="Ysabeau SC"
							fontWeight={700}
							fontSize={18}
						>
							ProtocolScout &copy; 2023
						</Typography>
						<Typography variant="subtitle1" component="span">
							Built by{" "}
							<Link
								to="https://algometric.net"
								target="_blank"
								className={"link-styling"}
								style={{
									marginLeft: "4px",
									fontFamily: "Ysabeau SC",
									fontWeight: 700,
									fontSize: 18,
								}}
							>
								AlgoMetric
							</Link>
						</Typography>
					</Box>
					<Box
						sx={{
							display: "flex",
							margin: "0 ",
							flexDirection: "row",
							alignItems: "center",
							flexWrap: "wrap",
							marginBottom: { xs: 1, md: 0 },
							justifyContent: "center",
							paddingX: { xs: 2, md: 0 },
							gap: 2,
						}}
					>
						<Typography
							variant="h6"
							component="span"
							fontFamily="Ysabeau SC"
							fontWeight={700}
						>
							<Link
								to="/terms-of-service/"
								className={"link-styling"}
								onClick={() => window.scrollTo(0, 0)}
							>
								Terms of Service
							</Link>
						</Typography>
						<Typography
							variant="h6"
							component="span"
							fontFamily="Ysabeau SC"
							fontWeight={700}
						>
							<Link
								to="/privacy-policy/"
								className={"link-styling"}
								onClick={() => window.scrollTo(0, 0)}
							>
								Privacy Policy
							</Link>
						</Typography>
						<Typography
							variant="h6"
							component="span"
							fontFamily="Ysabeau SC"
							fontWeight={700}
						>
							<Link
								to="about-us/"
								className={"link-styling"}
								onClick={() => window.scrollTo(0, 0)}
							>
								About Us
							</Link>
						</Typography>
						<Typography
							variant="h6"
							component="span"
							fontFamily="Ysabeau SC"
							fontWeight={700}
						>
							<Link
								className={"link-styling"}
								to="https://www.linkedin.com/in/sinisaradovcic"
								target="_blank"
							>
								Contact Us
							</Link>
						</Typography>
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
};
