/* eslint-disable array-callback-return */
import {
	NetworkCoinsWrapper,
	SearchByAdressForm,
	OptionalSearchForm,
	TransactionsFrom,
	SpecializedTransactionForm,
} from "../components/index";
import React, { useState, useEffect } from "react";
import {
	BlockchainDataModel,
	SearchCriteria,
	SingleNetwork,
	TableDataModel,
	AssetsModel,
	TypeOfRequest,
	TypeOfTable,
	TypeOfTableData,
	NetworkModal,
} from "../models/models";
import { load } from "recaptcha-v3";
import { Stack, Typography, Box, Link, Divider } from "@mui/material";
import { api, Table, Seo, GenericResponse, sorter } from "../utilities/index";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ReactGA from "react-ga4";

import {
	TransactionDataResponse,
	TransactionDetails,
	TransactionDetailsAddress,
	TransactionDetailsTableModel,
	TransactionRequest,
} from "../models/transactions";
import {
	useLocation,
	useNavigate,
	useSearchParams,
	useParams,
} from "react-router-dom";

export const HomePage: React.FC = () => {
	let timezones: any = [];
	const navigate = useNavigate();
	const params = useParams();
	const timeZoneUser = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
	const [intitalLoad, setInitialLoad] = useState<boolean>(false);
	if (typeof Intl.supportedValuesOf !== "undefined") {
		timezones = Intl.supportedValuesOf("timeZone");
	}
	const [balancesValue, setBalancesValue] = useState<BlockchainDataModel>({
		walletAddress: "",
		timeZone: timeZoneUser,
		dateTime: null,
		requestQuerryParams: "",
	});
	const premiumMessage =
		"Supported in the premium version only \u2014 please contact us";

	const [customizedBalancesValue, setCustomizedBalancesValue] =
		useState<BlockchainDataModel>({
			walletAddress: "",
			timeZone: timeZoneUser,
			dateTime: null,
			blockNumber: "",
			networkId: null,
			tokenContractAddresses: [""],
			requestQuerryParams: "",
		});

	// const [customizedTransactionsValue, setcustomizedTransactionsValue] =
	// 	useState<BlockchainDataModel>({
	// 		walletAddress: "",
	// 		timeZone: timeZoneUser,
	// 		dateTime: null,
	// 		blockNumber: "",
	// 		networkId: null,
	// 		tokenContractAddresses: [""],
	// 		requestQuerryParams: "",
	// 	});
	// const [transactionPageNumber, setTransactionPageNumber] =
	// 	useState<number>(0);
	const [amountOfTransactionItems, setAmountOfTransactionItems] =
		useState<number>(0);
	const { pathname } = useLocation();
	const [searchParams] = useSearchParams();
	const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
		coinAmount: 0,
		tokensAmount: 0,
	});
	const [customizedsearchCriteria, setCustomizedSearchCriteria] =
		useState<SearchCriteria>({
			coinAmount: 1,
			tokensAmount: 0,
		});

	const [dataForAdress, setDataForAdress] = useState<TableDataModel[]>([]);
	const [networkCoins, setNetworkCoins] = useState<NetworkModal[]>([]);
	const [dataForBlockNumber, setDataForBlockNumber] = useState<
		TableDataModel[]
	>([]);
	const [dataForTransactionDetails, setDataForTransactionDetails] = useState<
		TransactionDetailsTableModel[]
	>([]);
	const [
		dataForCustomizedTransactions,
		setDataForCustomizedTransactionDetails,
	] = useState<TransactionDetailsTableModel[]>([]);
	const [searchHashForTransactionDetails, setSearchHashForTransactionDetails] =
		useState<string>("");
	const [searchAddress, setSearchAddress] = useState("");
	const [errorMsg, setErrorMsg] = useState<null | string>(null);
	const [errorMsgForOptForm, setErrorMsgForOptForm] = useState<null | string>(
		null
	);
	const [
		errorMsgForCustomizedTransactions,
		setErrorMsgForCustomizedTransactions,
	] = useState<null | string>(null);
	const [errorMsgTransaction, setErrorMsgTransaction] = useState<
		null | string
	>(null);
	const [searchAddressForTransactions, setSearchAddressForTransactions] =
		useState<string>("");
	const [
		searchAddressForCustomizedTransactions,
		setSearchAddressForCustomizedTransactions,
	] = useState<string>("");
	const [transactionsData, setTransactionsData] = useState<
		TransactionDataResponse[]
	>([]);
	const [
		searchAddressForCustomizedBalances,
		setSearchAddressForCustomizedBalances,
	] = useState<string>("");
	const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
	const [token, setToken] = useState<string>("");
	const dataForPremiumTokens = GenericResponse(
		"Supported in the premium version only \u2014 please contact us"
	);

	const [heightOfTransactionElement, setHeightOfTransactionElement] =
		useState<number>(0);
	const [heightofBalancesElement, setHeightOfBalancesElement] =
		useState<number>(0);
	const [
		heightofCustomizedBalancesElement,
		setheightofCustomizedBalancesElement,
	] = useState<number>(0);
	const [errorMsgForGetNetworks, setErrorMsgForGetNetworks] =
		useState<string>("");

	useEffect(() => {
		if (!intitalLoad) {
			setRecaptcha();
			getNetworkCoins();
		}
		setInitialLoad(true);
		// eslint-disable-next-line
	}, []);
	useEffect(() => {
		if (pathname === "/") {
			navigate("all/");
		}
		if (pathname.includes("/transactionsByHash/")) {
			const hash = pathname.substring(1);
			checkTransactionDetails(hash);

			window.scroll({
				top: heightOfTransactionElement,
				behavior: "smooth",
			});
		}
		if (params.typeOfForm === "balances") {
			let requestBody!: BlockchainDataModel;

			if (!searchParams.get("networkId")) {
				requestBody = {
					walletAddress: params.address || "",
					timeZone: searchParams.get("timezone") || timeZoneUser,
					requestQuerryParams: `?walletAddress=${params.address}&timezone${
						searchParams.get("timezone") || timeZoneUser
					}`,
				};
				if (searchParams.get("datetime")) {
					const date =
						searchParams.get("datetime")?.replace(/ /g, "+") || "";
					const dateForQp = date
						? new Date(decodeURIComponent(date))
								.toISOString()
								.slice(0, 19)
								.replace("T", " ")
						: "";
					requestBody.dateTime =
						searchParams.get("datetime")?.replace(/ /g, "+") || "";
					requestBody.requestQuerryParams =
						requestBody.requestQuerryParams + `&dateTime=${dateForQp}`;
				} else {
					requestBody.isLatestReq = true;
				}
				setBalancesValue(requestBody);
				handleGetBalances(TypeOfRequest.SearchByAdress, requestBody);
				window.scroll({
					top: heightofBalancesElement,
					behavior: "smooth",
				});
			} else {
				requestBody = {
					walletAddress: params.address || "",
					timeZone: searchParams.get("timezone") || timeZoneUser,
					requestQuerryParams: `?walletAddress=${
						params.address
					}&timeZone=${searchParams.get("timezone") || timeZoneUser}`,
				};
				if (searchParams.get("networkId")) {
					requestBody.networkId = searchParams.get("networkId");
					requestBody.requestQuerryParams =
						requestBody.requestQuerryParams +
						`&networkId=${searchParams.get("networkId")}`;
				}
				if (searchParams.get("blocknumber")) {
					requestBody.blockNumber = searchParams.get("blocknumber") || "";
					requestBody.requestQuerryParams =
						requestBody.requestQuerryParams +
						`&blockNumber=${searchParams.get("blocknumber")}`;
				} else if (searchParams.get("datetime")) {
					const date =
						searchParams.get("datetime")?.replace(/ /g, "+") || "";
					const dateForQp = date
						? new Date(decodeURIComponent(date))
								.toISOString()
								.slice(0, 19)
								.replace("T", " ")
						: "";
					requestBody.dateTime =
						searchParams.get("datetime")?.replace(/ /g, "+") || "";
					requestBody.requestQuerryParams =
						requestBody.requestQuerryParams + `&dateTime=${dateForQp}`;
				} else {
					requestBody.isLatestReq = true;
				}

				if (searchParams.get("cta")) {
					requestBody.requestQuerryParams =
						requestBody.requestQuerryParams +
						`&assetsContractAddresses[0]=${searchParams.get("cta")}`;
					requestBody.tokenContractAddresses = [
						searchParams.get("cta") || "",
					];
				}
				setCustomizedBalancesValue(requestBody);
				handleGetBalances(TypeOfRequest.OptionalSearch, requestBody);
				window.scroll({
					top: heightofCustomizedBalancesElement,
					behavior: "smooth",
				});
			}
		}
		if (pathname === "/" || pathname === "/all/") {
			cleanupHandler();
			setBalancesValue({
				walletAddress: "",
				timeZone: timeZoneUser,
				dateTime: null,
				requestQuerryParams: "",
			});
			setCustomizedBalancesValue({
				walletAddress: "",
				timeZone: timeZoneUser,
				dateTime: null,
				blockNumber: "",
				networkId: null,
				tokenContractAddresses: [""],
				requestQuerryParams: "",
			});
		}

		// eslint-disable-next-line
	}, [pathname]);
	useEffect(() => {
		if (params.typeOfForm === "balances") {
			if (searchParams.get("networkId")) {
				const rexesArray: boolean[] = [];
				const selectedProtocol = networkCoins?.find(
					(network: NetworkModal): boolean => {
						return (
							network.id.toString() === searchParams?.get("networkId")
						);
					}
				);

				const regex = new RegExp(
					selectedProtocol?.walletValidationRegex || ""
				);
				rexesArray.push(regex.test(params.address || ""));
				console.log(selectedProtocol);
				if (selectedProtocol) {
					setCustomizedSearchCriteria({
						coinAmount: 1,
						tokensAmount: searchParams.get("cta")
							? 2
							: selectedProtocol.assetsNumber,
					});
				}
			} else {
				const rexesArray: boolean[] = [];
				let numberOfCoins = 0;
				let numberOfTokens = 0;
				networkCoins?.map((network: NetworkModal): void => {
					const regex = new RegExp(network.walletValidationRegex);
					rexesArray.push(regex.test(params.address || ""));

					if (regex.test(params.address || "")) {
						numberOfCoins++;
						numberOfTokens = numberOfTokens + network.assetsNumber;
					}
				});

				setSearchCriteria({
					coinAmount: numberOfCoins,
					tokensAmount: numberOfTokens,
				});
			}
		}
	}, [networkCoins, params, searchParams, dataForBlockNumber]);

	useEffect(() => {
		if (params.typeOfForm === "balances") {
			if (!searchParams.get("networkId")) {
				window.scroll({
					top: heightofBalancesElement,
					behavior: "smooth",
				});
			} else {
				window.scroll({
					top: heightofCustomizedBalancesElement,
					behavior: "smooth",
				});
			}
		}
	}, [heightOfTransactionElement, heightofBalancesElement]);
	const cleanupHandler = () => {
		balancesCleanupHandler();
		transactionCleanupHandler();
		customizedBalancesCleanupHandler();
		transactionDetailsCleanupHandler();
		customizedTransactionsCleanupHandler();
	};

	const balancesCleanupHandler = () => {
		setErrorMsg(null);
		setSearchAddress("");
		setDataForAdress([]);
		setSearchCriteria({ coinAmount: 0, tokensAmount: 0 });
	};
	const transactionCleanupHandler = () => {
		setErrorMsgTransaction(null);
		setSearchAddressForTransactions("");
		setTransactionsData([]);
		setSearchHashForTransactionDetails("");
	};
	const customizedBalancesCleanupHandler = () => {
		setErrorMsgForOptForm("");
		setSearchAddressForCustomizedBalances("");
		setDataForBlockNumber([]);
		setCustomizedSearchCriteria({ coinAmount: 0, tokensAmount: 0 });
	};

	const customizedTransactionsCleanupHandler = () => {
		setErrorMsgForCustomizedTransactions("");
		setDataForCustomizedTransactionDetails([]);
	};
	const transactionDetailsCleanupHandler = () => {
		setDataForTransactionDetails([]);
		setSearchHashForTransactionDetails("");
	};

	const setRecaptcha = async () => {
		const recaptcha = await load(`${recaptchaKey}`);
		if (isMobileDevice) {
			recaptcha.hideBadge();
		} else {
			recaptcha.showBadge();
		}
		const token = await recaptcha.execute("sendRequest");
		setToken(token);
	};

	const waitUntilTokenIsAvailable = async (
		address: string,
		typeOfRequest: TypeOfRequest
	) => {
		let token;
		if (typeOfRequest === TypeOfRequest.SearchByAdress) {
			setSearchAddress(address);
		} else {
			setSearchAddressForCustomizedBalances(address);
		}
		const recaptcha = await load(`${recaptchaKey}`);
		if (isMobileDevice) {
			recaptcha.hideBadge();
		} else {
			recaptcha.showBadge();
		}
		while (!token) {
			token = await recaptcha.execute("sendRequest");
			if (!token) {
				await new Promise((resolve) => setTimeout(resolve, 500));
			}
		}
		return token;
	};

	const onFileUpload = (typeOfTable: TypeOfTable, isPremium: boolean) => {
		if (typeOfTable === TypeOfTable.CustomizedTransactions) {
			const eventData = {
				category: "Upload Document",
				action: "Click_on_transaction_upload",
				label: "Click on Transaction Upload",
				event_category: "Upload",
			};
			ReactGA.event("Click_on_transaction_upload", eventData);
			customizedBalancesCleanupHandler();
			customizedTransactionsCleanupHandler();
			setErrorMsgForCustomizedTransactions(
				`Supported in the premium version only \u2014 please contact us.`
			);
		}

		if (typeOfTable === TypeOfTable.CustomizedBalances) {
			const eventData = {
				category: "Upload Document",
				action: "Click_on_balances_upload",
				label: "Click on Balances Upload",
				event_category: "Upload",
			};
			ReactGA.event("Click_on_balances_upload", eventData);
			customizedBalancesCleanupHandler();
			customizedTransactionsCleanupHandler();
			setErrorMsgForOptForm(
				`Supported in the premium version only \u2014 please contact us.`
			);
		}
	};

	const checkTransactionDetails = async (hashTag: string) => {
		transactionDetailsCleanupHandler();
		transactionCleanupHandler();
		if (!isMobileDevice) {
			balancesCleanupHandler();
		}
		setSearchHashForTransactionDetails(hashTag);
		const transactionHash = hashTag.includes("transactionsByHash")
			? hashTag.split("transactionsByHash/")[1].slice(0, -1)
			: hashTag;

		try {
			const response = await api.get(
				`get-transaction-details?txHash=${transactionHash}`,
				{
					headers: {
						"recaptcha-response-token": `${token}`,
						"Cache-Control": "public, max-age=5",
					},
				}
			);
			setSearchHashForTransactionDetails("");
			const { data }: { data: TransactionDetails } = response;

			const dataArray: TransactionDetailsTableModel[] = [];
			if (data.receivers.length > data.senders.length) {
				data.receivers.map(
					(transaction: TransactionDetailsAddress, index: number) => {
						let sender = data.senders[index] || {};

						let transactionsData = {
							id: transaction.index,
							hash: data.details.hash,
							feeValue: data.details.feeValue,
							inputValue: data.details.inputValue,
							inputValueUsd: data.details.inputValueUsd,
							blockHeight: data.details.blockHeight,
							dateTimeUtc: data.details.dateTimeUtc,
							index: transaction.index,
							address: transaction.address,
							annotation: transaction.annotation,
							value: transaction.value,
							valueUsd: transaction.valueUsd,
							indexFrom: sender.index,
							addressFrom: sender.address || "",
							annotationFrom: sender.annotation || "",
							valueFrom: sender.value,
							valueUsdFrom: sender.valueUsd,
						};
						dataArray.push(transactionsData);
					}
				);
				setDataForTransactionDetails(dataArray);
			} else {
				data.senders.map(
					(transaction: TransactionDetailsAddress, index: number) => {
						let receiver = data.receivers[index] || {};
						let transactionData = {
							id: transaction.index,
							hash: data.details.hash,
							feeValue: data.details.feeValue,
							inputValue: data.details.inputValue,
							inputValueUsd: data.details.inputValueUsd,
							blockHeight: data.details.blockHeight,
							dateTimeUtc: data.details.dateTimeUtc,
							index: receiver.index,
							address: receiver.address,
							annotation: receiver.annotation,
							value: receiver.value,
							valueUsd: receiver.valueUsd,
							indexFrom: transaction.index,
							addressFrom: transaction.address,
							annotationFrom: transaction.annotation,
							valueFrom: transaction.value,
							valueUsdFrom: transaction.valueUsd,
						};
						dataArray.push(transactionData);
					}
				);

				setDataForTransactionDetails(dataArray);
			}
		} catch (error: any) {
			console.log(error);
			setSearchAddressForTransactions("");
			setErrorMsgTransaction(
				error.response?.data?.title
					? error.response?.data?.title
					: error.message || "Something went wrong"
			);
		}
	};

	const sendTransactionRequest = async (
		typeOfRequest: TypeOfRequest,
		body: TransactionRequest,
		isTransactionByHash: boolean
	) => {
		setRecaptcha();
		if (typeOfRequest === TypeOfRequest.SearchByAdress) {
			if (isTransactionByHash) {
				transactionDetailsCleanupHandler();
				transactionCleanupHandler();
				if (!isMobileDevice) {
					balancesCleanupHandler();
				}
				checkTransactionDetails(body.walletAddress);
			} else {
				transactionDetailsCleanupHandler();
				transactionCleanupHandler();
				if (!isMobileDevice) {
					balancesCleanupHandler();
				}
				setSearchAddressForTransactions(body.walletAddress);
				window.history.replaceState(null, "Protocol Scout", "/");
			}

			setTimeout(() => {
				setSearchAddressForTransactions("");
				setErrorMsgTransaction(
					`Supported in the premium version only \u2014 please contact us.`
				);
			}, 1000);
		} else {
			if (!isMobileDevice) {
				customizedBalancesCleanupHandler();
			}
			customizedTransactionsCleanupHandler();

			window.history.replaceState(null, "Protocol Scout", "/");
		}
		setTimeout(() => {
			setSearchAddressForCustomizedTransactions("");
			setErrorMsgForCustomizedTransactions(
				`Supported in the premium version only \u2014 please contact us.`
			);
		}, 1000);

		// try {
		// 	const response = await api.post(
		// 		"get-transactions",
		// 		{ ...body, pageNumber: transactionPageNumber + 1 },
		// 		{
		// 			headers: {
		// 				"recaptcha-key": `${token}`,
		// 				"Cache-Control": "public, max-age=3600",
		// 			},
		// 		}
		// 	);
		// setAmountOfTransactionItems(response.data.value.totalCount);
		// setSearchAddressForTransactions("");
		// if (response.data.value.items.length === 0) {
		// 	setErrorMsgTransaction(
		// 		"There is no data for provided address / transaction hash"
		// 	);
		// } else {
		// 	setTransactionsData(
		// 		[
		// 			...response.data.value.items.map(
		// 				(data: TransactionDataResponse, index: number) => {
		// 					return { ...data, id: index + 1 };
		// 				}
		// 			),
		// 		].sort(
		// 			(a: TransactionDataResponse, b: TransactionDataResponse) => {
		// 				return a.inputValue - b.inputValue;
		// 			}
		// 		)
		// 	);
		// }
		// 	} catch (error: any) {
		// 		setSearchAddressForTransactions("");
		// 		setErrorMsgTransaction(
		// 			error.response?.data?.title
		// 				? error.response?.data?.title
		// 				: error.message || "Something went wrong"
		// 		);
		// 	}
		// }
	};
	const handleGetBalances = async (
		typeOfRequest: TypeOfRequest,
		body: BlockchainDataModel
	) => {
		let queryParams = "";
		if (body.networkId) {
			const rootQp = `?networkId=${body.networkId}`;
			const dateQp = body.dateTime ? `&datetime=${body.dateTime}` : "";
			const block = body.blockNumber
				? `&blocknumber=${body.blockNumber}`
				: dateQp;
			const timezone =
				body.timeZone === timeZoneUser ? "" : `&timezone=${body.timeZone}`;
			const contractTokenQp =
				body.tokenContractAddresses && body.tokenContractAddresses[0]
					? `&cta=${body.tokenContractAddresses[0]}`
					: "";
			queryParams = `${rootQp}${block}${timezone}${contractTokenQp}`;
		} else {
			const dateTimeQp = `${
				body.dateTime ? "?datetime=" + body.dateTime : ""
			}`;
			queryParams =
				dateTimeQp.length > 0
					? body.timeZone === timeZoneUser
						? dateTimeQp
						: `${dateTimeQp}&timezone=${body.timeZone}`
					: `${
							body.timeZone !== timeZoneUser
								? "?timezone=" + body.timeZone
								: ""
					  } `;
		}

		window.history.replaceState(
			null,
			"Protocol Scout",
			`/all/balances/${body.walletAddress}${queryParams}`
		);
		setRecaptcha();

		if (typeOfRequest === TypeOfRequest.SearchByAdress) {
			balancesCleanupHandler();
			const token = await waitUntilTokenIsAvailable(
				body.walletAddress,
				TypeOfRequest.SearchByAdress
			);
			if (!isMobileDevice) {
				transactionDetailsCleanupHandler();
				transactionCleanupHandler();
			}
			setSearchAddress(body.walletAddress);

			if (
				body.searchCriteria?.coinAmount !== 0 &&
				body.searchCriteria?.tokensAmount !== 0
			) {
				body.searchCriteria && setSearchCriteria(body.searchCriteria);
			}
			delete body.searchCriteria;
			setRecaptcha();
			if (token.length === 0) {
				setTimeout(() => {}, 500);
			}

			try {
				const response = await api.get(
					`get-balances${body.requestQuerryParams.replace(/\//g, "%2F")}`,

					{
						headers: {
							"recaptcha-response-token": `${token}`,
							"Cache-Control": "public, max-age=5",
						},
					}
				);
				setSearchAddress("");
				const { data } = response;
				let dataArr: TableDataModel[] = [];

				data.networks.map((network: SingleNetwork): void => {
					if (network.providerBalance && network.providerBalance.assets) {
						network.providerBalance.assets.map(
							(to: AssetsModel): void => {
								if (to.error === "SubscriptionLevel.PremiumOnly") {
									let networkCoin = {
										...dataForPremiumTokens,
										networkName: network.network.name,
										walletAddress: data.walletAddress,
										dateTimeUtc: "",
										name: to.asset.name,
										dateTime: "",
										id: to.asset.name + to.asset.id,
									};

									dataArr.push(
										networkCoin as unknown as TableDataModel
									);
								} else {
									let currentValue: TableDataModel = {
										humanTms: "",
										unixTms: network.providerBalance.dateTimeUtc
											? Math.floor(
													new Date(
														network.providerBalance.dateTimeUtc.slice(
															0,
															19
														) || ""
													).getTime() / 1000
											  )
											: "",
										blockNumber: network.providerBalance.blockNumber,
										dateTime: body.isLatestReq
											? `"latest"`
											: body.dateTime
											? body.dateTime
											: body.blockNumber ||
											  new Date().toString().slice(0, 31),
										dateTimeUtc: new Date()
											.toISOString()
											.slice(0, 19),
										unixTimeUTC:
											network.providerBalance.dateTimeUtc || "",
										walletAddress: data.walletAddress,
										networkName: network.network.name,
										iconPath: to.asset.iconPath,
										decimalPlaces: to.asset.decimalPlaces,
										name: to.asset.name,
										tokenImg: to.asset.iconPath,
										networkId: to.asset.networkId,
										shortName: to.asset.shortName,
										id: to.asset.id,
										balance: +to.assetBalance,
										contractAddress: to.asset.contractAddress,
										networkShortName: to.asset.shortName,
										networkProviderName: network.providerBalance
											.provider
											? network.providerBalance.provider.name
											: "",
										networkProviderIconPath: network.providerBalance
											.provider
											? network.providerBalance.provider.iconPath
											: "",
									};

									dataArr.push(
										currentValue as unknown as TableDataModel
									);
								}
							}
						);
					}
				});

				if (dataArr.length !== 0) {
					const ids = dataArr.map((data: TableDataModel) => {
						return data.id;
					});
					const filtered = dataArr.filter(
						({ id }, index) => !ids.includes(id, index + 1)
					);

					setDataForAdress(
						sorter(filtered, TypeOfTableData.balancesTable)
					);
					const listOfNetworks: any[] = [];
					filtered.forEach((singleValue: any): void => {
						if (
							!listOfNetworks.includes(singleValue.networkName) &&
							singleValue.balance > 0
						) {
							listOfNetworks.push(singleValue.networkName);
						}
					});

					const coinsLength = listOfNetworks.length;

					const eventOptions = {
						category: "Basic Balances Request",
						action: "Get_balances",
						label: "Get Balances",
						event_category: "Balances",
						search_scope_networks: searchCriteria.coinAmount,
						search_scope_assets: searchCriteria.tokensAmount,
						result_rows: filtered.length,
						search_result_networks: coinsLength,
						search_result_assets: filtered.filter((singleValue: any) => {
							return singleValue.balance > 0;
						}).length,
						search_result_premium_assets: filtered.filter(
							(singleValue: any) => {
								return singleValue.balance === premiumMessage;
							}
						).length,
					};

					ReactGA.event("Get_balances", eventOptions);
				} else {
					setErrorMsg(
						"No data for provided address. Please check your address and time and try again"
					);
				}
			} catch (error: any) {
				setSearchAddress("");
				setErrorMsg(
					error.response?.data?.title
						? error.response?.data?.title
						: error.message || "Something went wrong"
				);
			}
		} else {
			customizedBalancesCleanupHandler();
			const token = await waitUntilTokenIsAvailable(
				body.walletAddress,
				TypeOfRequest.OptionalSearch
			);
			setSearchAddressForCustomizedBalances(body.walletAddress);
			if (!isMobileDevice) {
				customizedTransactionsCleanupHandler();
			}
			if (
				body.searchCriteria?.coinAmount !== 0 &&
				body.searchCriteria?.tokensAmount !== 0
			) {
				console.log(body.searchCriteria, "body");

				body.searchCriteria &&
					setCustomizedSearchCriteria(body.searchCriteria);
			}

			delete body.searchCriteria;

			if (body.isCustomContractAddress) {
				setTimeout(() => {
					setSearchAddressForCustomizedBalances("");
					setErrorMsgForOptForm(
						`Supported in the premium version only \u2014 please contact us.`
					);
				}, 1000);
			} else {
				try {
					const response = await api.get(
						`get-balances${body.requestQuerryParams.replace(
							/\//g,
							"%2F"
						)}`,

						{
							headers: {
								"recaptcha-response-token": `${token}`,
								"Cache-Control": "public, max-age=5",
							},
						}
					);
					setSearchAddressForCustomizedBalances("");
					let tokensAmount = 0;
					let coinsAmount = 0;
					const { data } = response;
					let dataArr: TableDataModel[] = [];
					data.networks.map((network: SingleNetwork): void => {
						if (
							network.providerBalance.error !==
							"Network.WalletAddressNotValid"
						) {
							coinsAmount++;
						}

						if (network.providerBalance.assets) {
							network.providerBalance.assets.map(
								(to: AssetsModel): void => {
									if (to.error === "SubscriptionLevel.PremiumOnly") {
										let networkCoin = {
											...dataForPremiumTokens,
											networkName: network.network.name,
											walletAddress: data.walletAddress,
											dateTimeUtc: "",
											name: to.asset.name,
											dateTime: "",
											id: to.asset.name + to.asset.id,
										};

										dataArr.push(
											networkCoin as unknown as TableDataModel
										);
									} else {
										let currentValue: TableDataModel = {
											blockNumber:
												network.providerBalance.blockNumber,
											unixTms: network.providerBalance.dateTimeUtc
												? Math.floor(
														new Date(
															network.providerBalance.dateTimeUtc.slice(
																0,
																19
															) || ""
														).getTime() / 1000
												  )
												: "",
											humanTms: "",
											dateTime: body.isLatestReq
												? `"latest"`
												: body.dateTime
												? body.dateTime
												: body.blockNumber ||
												  new Date().toString().slice(0, 31),
											dateTimeUtc: new Date()
												.toISOString()
												.slice(0, 19),
											unixTimeUTC:
												network.providerBalance.dateTimeUtc || "",
											walletAddress: data.walletAddress,
											tokenImg: to.asset.iconPath,
											networkName: network.network.name,
											decimalPlaces: to.asset.decimalPlaces,
											iconPath: to.asset.iconPath,
											name: to.asset.name,
											networkId: to.asset.networkId,
											shortName: to.asset.shortName,
											id: to.asset.id,
											networkShortName: to.asset.shortName,
											balance: +to.assetBalance,
											contractAddress: to.asset.contractAddress,
											networkProviderName: network.providerBalance
												.provider
												? network.providerBalance.provider.name
												: "",
											networkProviderIconPath: network
												.providerBalance.provider
												? network.providerBalance.provider.iconPath
												: "",
										};
										tokensAmount++;
										dataArr.push(
											currentValue as unknown as TableDataModel
										);
									}
								}
							);
						}

						const ids = dataArr.map((data: TableDataModel) => {
							return data.id;
						});
						const filtered = dataArr.filter(
							({ id }, index) => !ids.includes(id, index + 1)
						);
						setDataForBlockNumber(
							sorter(filtered, TypeOfTableData.balancesTable)
						);
						const listOfNetworks: any[] = [];
						dataForBlockNumber.forEach((singleValue: any): void => {
							if (
								!listOfNetworks.includes(singleValue.networkName) &&
								singleValue.balance > 0
							) {
								listOfNetworks.push(singleValue.networkName);
							}
						});

						const coinsLength = listOfNetworks.length;
						console.log(customizedsearchCriteria);
						const eventOptions = {
							category: "Customized Balances Request",
							action: "Get_customized_balances",
							label: "Get Customized Balances",
							event_category: "Balances",
							search_scope_networks:
								body.searchCriteria?.coinAmount ||
								customizedsearchCriteria.coinAmount,
							search_scope_assets:
								body.searchCriteria?.tokensAmount ||
								customizedsearchCriteria.tokensAmount,

							result_rows: filtered.length,

							search_result_networks: 1,
							search_result_assets: filtered.filter(
								(singleValue: any) => {
									return singleValue.balance > 0;
								}
							).length,
							search_result_premium_assets: filtered.filter(
								(singleValue: any) => {
									return singleValue.balance === premiumMessage;
								}
							).length,
						};
						ReactGA.event("Get_customized_balances", eventOptions);
					});
				} catch (error: any) {
					setSearchAddressForCustomizedBalances("");
					setErrorMsgForOptForm(
						error.response?.data?.title
							? error.response?.data?.title
							: error.message || "Something went wrong"
					);

					console.log(error);
				}
			}
		}
	};

	const getNetworkCoins = async () => {
		try {
			const response = await api.get("get-networks", {
				headers: {
					"Cache-Control": "public, max-age=3600",
				},
			});
			const { data } = response;
			setNetworkCoins(data.networks);
		} catch (error: any) {
			setErrorMsgForGetNetworks(error.message);
			console.log(error);
		}
	};

	return (
		<Stack
			component="main"
			sx={{
				gap: 3,

				display: "flex",
				flexDirection: "row",
				flexWrap: "wrap",

				justifyContent: {
					md: "space-between",
				},
				backgroundColor: "#f6f6f6",
				width: "100%",
			}}
		>
			<Seo
				description="Your Gateway To Token Wallet Monitoring"
				title="Home Page"
				type="website"
				url="https://protocolscout.com/"
			></Seo>

			<Box
				component="section"
				sx={{
					maxWidth: "1200px",
					marginX: "auto",
					width: "100%",
					padding: 2,
				}}
			>
				<Typography
					gutterBottom
					component="h1"
					sx={{
						fontSize: { xs: "2rem", md: "2.2rem" },
						fontWeight: 300,
						fontFamily: "sans-serif",
						paddingTop: 5,
						marginRight: "auto",
						marginBottom: 0,
						maxWidth: "1200px",
						marginX: "auto",
						width: "100%",
						paddingBottom: 3,
					}}
				>
					Multi-chain explorer optimized for
				</Typography>

				<Box
					sx={{
						width: "100%",
						paddingLeft: "15px",
					}}
					component={"article"}
				>
					<Typography variant="h4">
						Top{" "}
						<span style={{ letterSpacing: "5px" }}>
							&#36;&#8364;&#163;&#165;
						</span>{" "}
						STABLECOINS
					</Typography>
					<Typography variant="h4">ACCOUNTING industry</Typography>
					<Typography
						variant="h4"
						sx={{
							marginBottom: "0.2rem",
						}}
					>
						Financial AUDITS
					</Typography>{" "}
					<Link
						href="/about-us/"
						target="_blank"
						variant="body2"
						sx={{
							textDecoration: "none",
							color: "#000",
							display: "flex",
						}}
					>
						<Typography> All features </Typography>

						<OpenInNewIcon
							sx={{
								paddingLeft: 1,
								paddingTop: { xs: "7px", md: "4px" },
								fontSize: { xs: "1rem", md: "1.2rem" },
							}}
						/>
					</Link>{" "}
				</Box>
				<Typography
					variant="body2"
					sx={{
						margin: "24px 0 20px",
					}}
				>
					In the form below, submit a{" "}
					<Link href="#balances-form">blockchain address</Link> holding
					digital assets and ProtocolScout will respond with the current or
					past balances or{" "}
					<Link href="#transactions-form">transactions</Link> for all coins
					and tokens across each of the {networkCoins.length} protocols we
					support.
				</Typography>
			</Box>
			<NetworkCoinsWrapper
				network={networkCoins}
				error={errorMsgForGetNetworks}
			/>

			<Box
				component="section"
				sx={{
					width: "100%",
					padding: 2,
				}}
			>
				<Box
					sx={{
						maxWidth: "1200px",
						width: "100%",
						marginX: "auto",
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "space-between",
					}}
				>
					<SearchByAdressForm
						handleClick={handleGetBalances}
						browserTimeZone={timeZoneUser}
						timeZones={timezones}
						listOfNetworks={networkCoins}
						itemHeight={(el: number) => {
							setHeightOfBalancesElement(el);
						}}
						formData={balancesValue}
					/>
					<TransactionsFrom
						handleClickOnTransactions={sendTransactionRequest}
						browserTimeZone={timeZoneUser}
						timeZones={timezones}
						listOfNetworks={networkCoins}
						itemHeight={(el: number) => {
							setHeightOfTransactionElement(el);
						}}
					/>

					<Table
						data={dataForAdress}
						searchCriteria={searchCriteria}
						typeOFTable={TypeOfTable.Balances}
						errorMsg={errorMsg}
						searchAddress={searchAddress}
					/>

					<Table
						searchAddress={searchAddressForTransactions}
						data={transactionsData}
						searchCriteria={searchCriteria}
						typeOFTable={TypeOfTable.Transaction}
						maxItems={amountOfTransactionItems}
						errorMsg={errorMsgTransaction}
					/>

					<Table
						data={dataForTransactionDetails}
						typeOFTable={TypeOfTable.TransactionDetails}
						errorMsg={""}
						searchAddress={searchHashForTransactionDetails}
						searchCriteria={searchCriteria}
					/>
				</Box>
			</Box>

			<Divider
				sx={{
					width: "100%",
					maxWidth: "1200px",
					marginX: "auto",
					display: { xs: "none", sm: "none", md: "block", lg: "block" },
					marginY: 3,
				}}
			/>

			<Box
				sx={{
					width: "100%",
					padding: 2,
				}}
				component="section"
			>
				<Box
					sx={{
						maxWidth: "1200px",
						width: "100%",
						marginX: "auto",
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "space-between",
					}}
				>
					<OptionalSearchForm
						handleClick={handleGetBalances}
						browserTimeZone={timeZoneUser}
						timeZones={timezones}
						listOfNetworks={networkCoins}
						onFileUpload={onFileUpload}
						itemHeight={(el: number) => {
							setheightofCustomizedBalancesElement(el);
						}}
						formData={customizedBalancesValue}
					/>
					<SpecializedTransactionForm
						handleClickOnTransactions={sendTransactionRequest}
						browserTimeZone={timeZoneUser}
						timeZones={timezones}
						listOfNetworks={networkCoins}
						onFileUpload={onFileUpload}
						itemHeight={(el: number) => {
							setHeightOfTransactionElement(el);
						}}
					></SpecializedTransactionForm>

					<Table
						data={dataForBlockNumber}
						searchCriteria={customizedsearchCriteria}
						typeOFTable={TypeOfTable.CustomizedBalances}
						errorMsg={errorMsgForOptForm}
						searchAddress={searchAddressForCustomizedBalances}
					/>
					<Table
						data={dataForCustomizedTransactions}
						searchCriteria={customizedsearchCriteria}
						typeOFTable={TypeOfTable.CustomizedTransactions}
						errorMsg={errorMsgForCustomizedTransactions}
						searchAddress={searchAddressForCustomizedTransactions}
					/>
				</Box>
			</Box>
		</Stack>
	);
};
