import React, { useEffect, useState } from "react";
import {
	Box,
	CircularProgress,
	IconButton,
	Snackbar,
	Stack,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import {
	DataGrid,
	GridColDef,
	GridRenderCellParams,
	GridToolbar,
	GridPaginationModel,
	GridCellParams,
} from "@mui/x-data-grid";
import { SearchCriteria, TableDataModel, TypeOfTable } from "../models/models";
import {
	TransactionDataResponse,
	TransactionDetailsTableModel,
} from "../models/transactions";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export function Table({
	data,
	typeOFTable,
	onTablePageChange,
	errorMsg,
	searchAddress,
	searchCriteria,
	maxItems,
}: {
	data:
		| TableDataModel[]
		| TransactionDataResponse[]
		| TransactionDetailsTableModel[];

	typeOFTable: TypeOfTable;
	onTablePageChange?: (model: GridPaginationModel) => void;
	maxItems?: number;
	errorMsg: string | null;
	searchAddress: string;
	searchCriteria: SearchCriteria;
}) {
	const theme = useTheme();
	const premiumMessage =
		"Supported in the premium version only \u2014 please contact us";

	const [openToast, setOpenToast] = useState(false);

	const handleCopyContent = () => {
		setOpenToast(true);
	};
	const [assetAmount, setAssetAmount] = useState(0);
	const [searchNetworksAmount, setSearchNetworksAmount] = useState(0);
	const [premiumAssets, setPremiumAssets] = useState(0);
	const [searchRange, setSearchRange] =
		useState<SearchCriteria>(searchCriteria);

	useEffect(() => {
		if (
			typeOFTable === TypeOfTable.Balances ||
			typeOFTable === TypeOfTable.CustomizedBalances
		) {
			const listOfNetworks: any[] = [];
			data.forEach((singleValue: any): void => {
				if (
					!listOfNetworks.includes(singleValue.networkName) &&
					singleValue.balance > 0
				) {
					listOfNetworks.push(singleValue.networkName);
				}
			});

			const coinsLength = listOfNetworks.length;

			const assetAmounts = data.filter((singleValue: any) => {
				return singleValue.balance > 0;
			}).length;
			const premiumAssets = data.filter((singleValue: any) => {
				return singleValue.balance === premiumMessage;
			}).length;

			setPremiumAssets(premiumAssets);
			setSearchNetworksAmount(coinsLength);
			setAssetAmount(assetAmounts);
		}
	}, [data, typeOFTable]);
	useEffect(() => {
		if (
			searchCriteria.coinAmount !== 0 ||
			searchCriteria.tokensAmount !== 0
		) {
			setSearchRange(searchCriteria);
		}
	}, [searchCriteria]);

	const onContentCopy = (address: string) => {
		if (address) {
			const textToCopy = address;
			const tempInput = document.createElement("input");
			document.body.appendChild(tempInput);
			tempInput.value = textToCopy?.trim() || "";
			tempInput.select();
			document.execCommand("copy");
			document.body.removeChild(tempInput);
			handleCopyContent();
		}
	};

	const handleCloseToast = (
		event: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}

		setOpenToast(false);
	};

	const actionCloseToast = (
		<IconButton
			size="small"
			aria-label="close"
			color="inherit"
			onClick={handleCloseToast}
		>
			x
		</IconButton>
	);
	const orderingByTypeOfTable = (typeOfTable: TypeOfTable): number => {
		switch (typeOfTable) {
			case TypeOfTable.Balances:
				return theme.breakpoints.down("md") ? 2 : 3;
			case TypeOfTable.Transaction:
				return theme.breakpoints.down("md") ? 3 : 3;
			case TypeOfTable.CustomizedBalances:
				return theme.breakpoints.down("md") ? 6 : 8;
			case TypeOfTable.CustomizedTransactions:
				return 8;
			case TypeOfTable.TransactionDetails:
				return theme.breakpoints.down("md") ? 4 : 3;

			default:
				return 0;
		}
	};
	const columnsForBalancesTable: GridColDef[] = [
		{
			field: "networkProviderName",
			width: 120,
			headerName: "Source",
			sortable: true,
			align: "left",
			headerAlign: "left",
		},
		{
			field: "networkName",
			headerName: "ProtocolNetwork",
			align: "left",
			headerAlign: "left",
			width: 240,
			sortable: true,
		},
		{
			field: "name",
			width: 290,
			headerName: "AssetName",
			sortable: true,
			align: "left",
			headerAlign: "left",
		},
		{
			field: "balance",
			width: 240,
			headerName: "Amount",
			align: "left",
			headerAlign: "left",
			type: "number",
			sortable: true,
			renderCell: (params: GridRenderCellParams) => {
				const formattedBalance = new Intl.NumberFormat("en-US", {
					maximumFractionDigits: 19,
				}).format(params.row.balance);
				return (
					<Stack alignItems={"center"} justifyContent={"center"}>
						{params.row.balance === premiumMessage && (
							<Tooltip
								componentsProps={{
									tooltip: {
										sx: {
											borderRadius: "4px",
											border: "1px solid #808080",
											bgcolor: "#fff",
											color: "#000",
											fontSize: "0.875rem",
											fontWeight: 500,
											textAlign: "center",
											width: "180px",
											"& .MuiTooltip-arrow": {
												color: "#fff",
											},
										},
									},
								}}
								title={params.row.balance}
								placement="top"
							>
								<Box
									sx={{
										display: "flex",
										gap: 0.5,
										alignItems: "center",
									}}
								>
									<Typography
										padding={0}
										margin={0}
										component={"p"}
										variant="h6"
									>
										Premium
									</Typography>
									<InfoOutlinedIcon sx={{ fontSize: "1rem" }} />
								</Box>
							</Tooltip>
						)}{" "}
						{params.row.balance !== premiumMessage && (
							<Typography variant="h6">{formattedBalance}</Typography>
						)}
					</Stack>
				);
			},
		},
		{
			field: "walletAddress",
			width: 260,
			headerName: "WalletAddress",
			align: "left",
			sortable: true,
			headerAlign: "left",
			renderCell: (params: GridRenderCellParams) => (
				<Stack alignItems={"center"} justifyContent={"center"}>
					<Tooltip
						componentsProps={{
							tooltip: {
								sx: {
									borderRadius: "4px",
									border: "1px solid #808080",
									bgcolor: "#fff",
									color: "#000",
									fontSize: "0.875rem",
									fontWeight: 500,

									"& .MuiTooltip-arrow": {
										color: "#fff",
									},
								},
							},
						}}
						title={params.row.walletAddress}
						placement="top"
					>
						<Typography
							padding={0}
							margin={0}
							component={"p"}
							variant="h6"
							onClick={() => {
								onContentCopy(params.row.walletAddress);
							}}
							sx={{
								maxWidth: "250px",
							}}
						>
							{params.row.walletAddress &&
							params.row.walletAddress.length > 0
								? params.row.walletAddress.substring(0, 8) +
								  "..." +
								  params.row.walletAddress.slice(
										-8,
										params.row.walletAddress.length
								  )
								: ""}{" "}
							{params.row.walletAddress && (
								<ContentCopyOutlinedIcon sx={{ fontSize: "1.1rem" }} />
							)}
						</Typography>
					</Tooltip>
				</Stack>
			),
		},
		{
			field: "dateTimeUtc",
			width: 330,
			align: "left",
			headerAlign: "left",
			headerName: "DataRetrievalTms(UTC)",
			sortable: true,
		},

		{
			field: "dateTime",
			width: 330,
			headerName: "UserTms/BlockNumber",
			align: "left",
			headerAlign: "left",
			sortable: true,
		},
		{
			field: "blockNumber",
			width: 170,
			headerName: "BlockNumber",

			sortable: true,
			align: "left",
			headerAlign: "left",
		},
		{
			field: "unixTms",
			width: 170,
			headerName: "BlockTmsUnix",
			sortable: true,
			align: "left",
			headerAlign: "left",
		},
		{
			field: "unixTimeUTC",
			width: 280,
			headerName: "BlockTmsHuman",
			sortable: true,
			align: "left",
			headerAlign: "left",
		},

		{
			field: "contractAddress",
			width: 270,
			headerName: "TokenContractAddress",
			sortable: true,
			align: "left",
			headerAlign: "left",
			renderCell: (params: GridRenderCellParams) => (
				<Stack alignItems={"center"} justifyContent={"center"}>
					{params.row.contractAddress !== premiumMessage && (
						<Tooltip
							componentsProps={{
								tooltip: {
									sx: {
										borderRadius: "4px",
										border: "1px solid #808080",
										bgcolor: "#fff",
										color: "#000",
										fontSize: "0.875rem",
										fontWeight: 500,
										"& .MuiTooltip-arrow": {
											color: "#fff",
										},
									},
								},
							}}
							title={params.row.contractAddress}
							placement="top"
						>
							<Typography
								padding={0}
								margin={0}
								component={"p"}
								variant="h6"
								sx={{
									maxWidth: "250px",
								}}
							>
								{params.row.contractAddress &&
								params.row.contractAddress.length > 0
									? params.row.contractAddress.substring(0, 8) +
									  "..." +
									  params.row.contractAddress.slice(
											-8,
											params.row.contractAddress.length
									  )
									: ""}
							</Typography>
						</Tooltip>
					)}
					{params.row.contractAddress === premiumMessage && (
						<Tooltip
							componentsProps={{
								tooltip: {
									sx: {
										borderRadius: "4px",
										border: "1px solid #808080",
										bgcolor: "#fff",
										color: "#000",
										fontSize: "0.875rem",
										fontWeight: 500,
										"& .MuiTooltip-arrow": {
											color: "#fff",
										},
									},
								},
							}}
							title={params.row.contractAddress}
							placement="top"
						>
							<Box
								sx={{ display: "flex", gap: 0.5, alignItems: "center" }}
							>
								<Typography
									padding={0}
									margin={0}
									component={"p"}
									variant="h6"
								>
									Premium
								</Typography>
								<InfoOutlinedIcon sx={{ fontSize: "1rem" }} />
							</Box>
						</Tooltip>
					)}
				</Stack>
			),
		},
	];

	const columnstForTransactionsTable: GridColDef[] = [
		{
			field: "assetName",
			width: 160,
			headerName: "AssetName",
			align: "left",
			headerAlign: "left",
			sortable: false,
		},
		{
			field: "hash",
			width: 240,
			headerName: "TransactionHash",
			align: "left",
			headerAlign: "left",
			sortable: false,
			renderCell: (params: GridRenderCellParams) => (
				<Stack
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Tooltip
						componentsProps={{
							tooltip: {
								sx: {
									maxWidth: "240px",
									borderRadius: "4px",
									border: "1px solid #808080",
									bgcolor: "#fff",
									color: "#000",
									fontSize: "0.875rem",
									fontWeight: 500,
									"& .MuiTooltip-arrow": {
										color: "#fff",
									},
								},
							},
						}}
						title={params.row.hash}
						placement="top"
					>
						<Typography
							padding={0}
							margin={0}
							component={"p"}
							sx={{
								width: "175px",
							}}
						>
							{params.row.hash.substring(0, 8) +
								"..." +
								params.row.hash.slice(-8, params.row.hash.length)}{" "}
						</Typography>
					</Tooltip>{" "}
					<OpenInNewIcon
						sx={{ paddingLeft: 1, width: "16px", height: "16px" }}
					/>
				</Stack>
			),
		},
		{
			field: "blockDateTimeUtc",
			width: 280,
			headerName: "BlockDateTime(UTC)",
			align: "left",
			headerAlign: "left",
			sortable: false,
		},
		{
			field: "transactionType",
			width: 160,
			headerName: "TransactionType",
			align: "left",
			headerAlign: "left",
			sortable: false,
		},
		{
			field: "feeValue",
			width: 160,
			headerName: "FeeValue",
			align: "left",
			headerAlign: "left",
			sortable: false,
		},
		{
			field: "blockHeight",
			width: 160,
			headerName: "BlockHeight",
			align: "left",
			headerAlign: "left",
			sortable: false,
		},
		{
			field: "inputValue",
			width: 200,
			headerName: "InputValue",
			align: "left",
			headerAlign: "left",
			sortable: false,
		},
		{
			field: "inputCount",
			width: 130,
			headerName: "InputCount",
			align: "left",
			headerAlign: "left",
			sortable: false,
		},
		{
			field: "outputValue",
			width: 200,
			headerName: "OutputValue",
			align: "left",
			headerAlign: "left",
			sortable: false,
		},
		{
			field: "outputCount",
			width: 130,
			headerName: "OutputCount",
			align: "left",
			headerAlign: "left",
			sortable: false,
		},
	];

	const columnsTableForTransactionDetails: GridColDef[] = [
		{
			field: "hash",
			width: 260,
			headerName: "TransactionHash",
			align: "left",
			headerAlign: "left",
			sortable: false,
			renderCell: (params: GridRenderCellParams) => (
				<Stack alignItems={"center"} justifyContent={"center"}>
					<Tooltip
						componentsProps={{
							tooltip: {
								sx: {
									maxWidth: "250px",
									borderRadius: "4px",
									border: "1px solid #808080",
									bgcolor: "#fff",
									color: "#000",
									fontSize: "0.875rem",
									fontWeight: 500,
									"& .MuiTooltip-arrow": {
										color: "#fff",
									},
								},
							},
						}}
						title={params.row.hash}
						placement="top"
					>
						<Typography
							padding={0}
							margin={0}
							component={"p"}
							sx={{
								maxWidth: "250px",
							}}
						>
							{params.row.hash.substring(0, 8) +
								"..." +
								params.row.hash.slice(-8, params.row.hash.length)}
						</Typography>
					</Tooltip>
				</Stack>
			),
		},
		{
			field: "indexFrom",
			width: 150,
			headerName: "IndexFrom",
			align: "left",
			headerAlign: "left",
			sortable: false,
		},

		{
			field: "valueFrom",
			width: 160,
			headerName: "ValueFrom",
			align: "left",
			headerAlign: "left",
			sortable: false,
		},

		{
			field: "addressFrom",
			width: 260,
			headerName: "AddressFrom",
			align: "left",
			headerAlign: "left",
			sortable: false,
			renderCell: (params: GridRenderCellParams) => (
				<Stack alignItems={"center"} justifyContent={"center"}>
					<Tooltip
						componentsProps={{
							tooltip: {
								sx: {
									maxWidth: "250px",
									borderRadius: "4px",
									border: "1px solid #808080",
									bgcolor: "#fff",
									color: "#000",
									fontSize: "0.875rem",
									fontWeight: 500,
									"& .MuiTooltip-arrow": {
										color: "#fff",
									},
								},
							},
						}}
						title={params.row.addressFrom}
						placement="top"
					>
						<Typography
							padding={0}
							margin={0}
							component={"p"}
							sx={{
								maxWidth: "250px",
							}}
						>
							{params.row.addressFrom
								? params.row.addressFrom.substring(0, 8) +
								  "..." +
								  params.row.addressFrom.slice(
										-8,
										params.row.addressFrom.length
								  )
								: ""}
						</Typography>
					</Tooltip>
				</Stack>
			),
		},

		{
			field: "annotationFrom",
			width: 160,
			headerName: "AnotationFrom ",
			align: "left",
			headerAlign: "left",
			sortable: false,
		},
		{
			field: "valueUsdFrom",
			width: 160,
			headerName: "ValueUsdFrom",
			align: "left",
			headerAlign: "left",
			sortable: false,
			renderCell: (params: GridRenderCellParams) => (
				<Stack alignItems={"center"} justifyContent={"center"}>
					<Tooltip
						componentsProps={{
							tooltip: {
								sx: {
									maxWidth: "250px",
									borderRadius: "4px",
									border: "1px solid #808080",
									bgcolor: "#fff",
									color: "#000",
									fontSize: "0.875rem",
									fontWeight: 500,
									"& .MuiTooltip-arrow": {
										color: "#fff",
									},
								},
							},
						}}
						title={params.row.valueUsdFrom}
						placement="top"
					>
						<Typography
							padding={0}
							margin={0}
							component={"p"}
							sx={{
								maxWidth: "150px",
							}}
						>
							{params.row.valueUsdFrom}
						</Typography>
					</Tooltip>
				</Stack>
			),
		},
		{
			field: "index",
			width: 150,
			headerName: "IndexTo",
			align: "left",
			headerAlign: "left",
			sortable: false,
		},
		{
			field: "value ",
			width: 160,
			headerName: "ValueTo",
			align: "left",
			headerAlign: "left",
			sortable: false,
		},

		{
			field: "address",
			width: 260,
			headerName: "AddressTo",
			align: "left",
			headerAlign: "left",
			sortable: false,
			renderCell: (params: GridRenderCellParams) => (
				<Stack alignItems={"center"} justifyContent={"center"}>
					<Tooltip
						componentsProps={{
							tooltip: {
								sx: {
									maxWidth: "250px",
									borderRadius: "4px",
									border: "1px solid #808080",
									bgcolor: "#fff",
									color: "#000",
									fontSize: "0.875rem",
									fontWeight: 500,
									"& .MuiTooltip-arrow": {
										color: "#fff",
									},
								},
							},
						}}
						title={params.row.address}
						placement="top"
					>
						<Typography
							padding={0}
							margin={0}
							component={"p"}
							sx={{
								maxWidth: "250px",
							}}
						>
							{params.row.address
								? params.row.address.substring(0, 8) +
								  "..." +
								  params.row.address.slice(
										-8,
										params.row.address.length
								  )
								: ""}
						</Typography>
					</Tooltip>
				</Stack>
			),
		},

		{
			field: "annotation",
			width: 260,
			headerName: "AnotationTo",
			align: "left",
			headerAlign: "left",
			sortable: false,
		},
		{
			field: "valueUsd",
			width: 200,
			headerName: "ValueUsdTo",
			align: "left",
			headerAlign: "left",
			sortable: false,
			renderCell: (params: GridRenderCellParams) => (
				<Stack alignItems={"center"} justifyContent={"center"}>
					<Tooltip
						componentsProps={{
							tooltip: {
								sx: {
									maxWidth: "250px",
									borderRadius: "4px",
									border: "1px solid #808080",
									bgcolor: "#fff",
									color: "#000",
									fontSize: "0.875rem",
									fontWeight: 500,
									"& .MuiTooltip-arrow": {
										color: "#fff",
									},
								},
							},
						}}
						title={params.row.valueUsd}
						placement="top"
					>
						<Typography
							padding={0}
							margin={0}
							component={"p"}
							sx={{
								whiteSpace: "nowrap",
								overflow: "hidden",
								textOverflow: "ellipsis",
								maxWidth: "150px",
							}}
						>
							{params.row.valueUsd}
						</Typography>
					</Tooltip>
				</Stack>
			),
		},
	];
	const onColumnActionHandle = (params: GridCellParams) => {
		if (typeOFTable === TypeOfTable.Transaction) {
			if (params.field === "hash") {
				window
					.open("/transactionsByHash/" + params.value + "/", "_blank")
					?.focus();
			}
		}
	};

	return (
		<Typography
			component="article"
			sx={{
				order: orderingByTypeOfTable(typeOFTable),
				width: "100%",
				margin: "auto",
			}}
		>
			{" "}
			<Snackbar
				open={openToast}
				autoHideDuration={2000}
				onClose={handleCloseToast}
				message="The address has been copied."
				action={actionCloseToast}
				sx={{ maxWidth: "300px" }}
			/>
			<Box>
				{errorMsg && (
					<Box
						sx={{
							display: "flex",
							maxWidth: { sm: "300px", md: "80vw" },
							margin: "auto",
						}}
						alignItems={"center"}
						justifyContent={"center"}
						height="200px"
					>
						<Typography
							component="p"
							color="red"
							fontSize={25}
							sx={{ textAlign: "center" }}
						>
							{errorMsg}
						</Typography>
					</Box>
				)}
				{searchAddress.length > 0 && (
					<Box
						sx={{
							display: "flex",
							margin: "auto",
							flexDirection: "column",
						}}
						alignItems={"center"}
						justifyContent={"center"}
						height="300px"
					>
						{" "}
						<Typography
							sx={{
								fontSize: { xs: "0.875rem", sm: "1rem" },
								textAlign: "center",
							}}
						>
							Retrieving{" "}
							{typeOFTable === TypeOfTable.Balances ||
							typeOFTable === TypeOfTable.CustomizedBalances
								? "balances"
								: "transaction"}{" "}
							data for
						</Typography>
						<Typography
							sx={{
								display: { xs: "block", sm: "none" },
								fontSize: "0.875rem",
								textAlign: "center",
								paddingBottom: "16px",
							}}
						>
							{searchAddress.substring(0, 13) +
								"..." +
								searchAddress.slice(-13, searchAddress.length)}
						</Typography>
						<Typography
							sx={{
								display: { xs: "none", sm: "block" },
								fontSize: "1rem",
								textAlign: "center",
								paddingBottom: "16px",
							}}
						>
							{searchAddress}
						</Typography>
						<CircularProgress size="100px" />
					</Box>
				)}
				{data.length !== 0 && (
					<Box>
						{(typeOFTable === TypeOfTable.Balances ||
							typeOFTable === TypeOfTable.CustomizedBalances) && (
							<Box
								sx={{
									paddingY: 2,
									display: "flex",
									flexDirection: "column",
									gap: 1,
								}}
							>
								<Typography variant="h6">
									Search scope: {searchRange.tokensAmount} {""}
									asset
									{searchRange.tokensAmount > 1 ? "s" : ""} on{" "}
									{searchRange.coinAmount} network
									{searchRange.coinAmount > 1 ? "s" : ""}.
								</Typography>
								<Typography
									variant="h6"
									sx={{
										display: "inline",
										alignItems: "center",
										flexWrap: "wrap",
									}}
								>
									{" "}
									Search results: {assetAmount} asset
									{assetAmount > 1 ? "s" : ""} with balances across{" "}
									{searchNetworksAmount} network
									{searchNetworksAmount > 1 ? "s" : ""}
									{premiumAssets === 0 ? "." : " "}
									{premiumAssets > 0 && (
										<span
											style={{
												display: "inline-flex", // Set display to inline-flex
												alignItems: "center",
												height: "24px",
												marginRight: "8px", // Add margin if necessary
											}}
										>
											({premiumAssets} assets are supported in the
											premium version only &mdash; please contact
											us).
										</span>
									)}
								</Typography>{" "}
							</Box>
						)}
						<DataGrid
							hideFooter={data.length < 100 ? true : false}
							density="compact"
							sx={{
								height: "460px",
								"& .MuiDataGrid-cellContent": {
									overflow: "unset",
									wordWrap: "break-word",
									whiteSpace: "pre-wrap",
								},
								"& .MuiDataGrid-cell": {
									overflow: "unset",
									wordWrap: "break-word",
									whiteSpace: "pre-wrap",
								},
								"& .MuiDataGrid-columnHeaderTitle": {
									color: "#0850CF",
								},
								"& .MuiDataGrid-filterIcon": {
									color: "#0850CF",
								},
								"& .MuiDataGrid-columnHeader:focus": {
									outline: "none",
								},
								"& .MuiDataGrid-columnHeader:focus-within": {
									outline: "1px solid #00000045",
								},

								order:
									typeOFTable === TypeOfTable.Balances ||
									typeOFTable === TypeOfTable.Transaction
										? theme.breakpoints.down("md")
											? 2
											: 4
										: theme.breakpoints.down("md")
										? 6
										: 8,
							}}
							style={{ fontSize: "16px" }}
							rows={data || []}
							onCellClick={onColumnActionHandle}
							columns={
								typeOFTable === TypeOfTable.Balances ||
								typeOFTable === TypeOfTable.CustomizedBalances
									? columnsForBalancesTable
									: typeOFTable === TypeOfTable.Transaction ||
									  typeOFTable === TypeOfTable.CustomizedTransactions
									? columnstForTransactionsTable
									: columnsTableForTransactionDetails
							}
							slots={{
								toolbar: GridToolbar,
							}}
						/>
					</Box>
				)}
			</Box>
		</Typography>
	);
}
