import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	TextField,
	Button,
	Stack,
	Grid,
	FormControl,
	Select,
	Typography,
	InputLabel,
	Box,
	MenuItem,
	OutlinedInput,
	Tooltip,
	Snackbar,
	IconButton,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import {
	BlockchainDataModel,
	HandleRequestProps,
	NetworkModal,
	SearchCriteria,
	TypeOfRequest,
} from "../../models/models";
import {
	LocalizationProvider,
	DateTimePicker,
	renderTimeViewClock,
} from "@mui/x-date-pickers";

import { FixedSizeList } from "react-window";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

export const SearchByAdressForm: React.FC<HandleRequestProps> = ({
	handleClick,
	timeZones,
	listOfNetworks,
	itemHeight,
	formData,
}) => {
	const [blockchainInputValue, setBlockchainInputValue] = useState(
		formData.walletAddress
	);
	const [errorMsg, setErrorMsg] = useState("");
	const [dateAndTime, setDateAndTime] = useState<Dayjs | null>(
		formData.dateTime ? dayjs(formData.dateTime) : null
	);
	const [dateError, setDateError] = useState(false);
	const [isListOpen, setListOpen] = useState(false);
	const textAreaRef = useRef<HTMLElement | null>(null);
	const [searchWithoutDateOrBlokc, setSearchWithoutDateORBlock] =
		useState(false);
	const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
		coinAmount: 0,
		tokensAmount: 0,
	});
	const buttonLocation = useRef<HTMLDivElement | null>(null);

	const onContentCopy = (e: any) => {
		if (textAreaRef.current) {
			const textToCopy = textAreaRef.current.textContent;
			const tempInput = document.createElement("input");
			document.body.appendChild(tempInput);
			tempInput.value = textToCopy?.trim() || "";
			tempInput.select();
			document.execCommand("copy");
			document.body.removeChild(tempInput);
			handleCopyContent();
		}
	};
	const handleInputValue = (e: any) => {
		const value = e.target.value;
		const rexesArray: boolean[] = [];
		let numberOfCoins = 0;
		let numberOfTokens = 0;
		listOfNetworks?.map((network: NetworkModal): void => {
			const regex = new RegExp(network.walletValidationRegex);
			rexesArray.push(regex.test(value));
			if (network.name === "Tron") {
				const regex = new RegExp(network.walletValidationRegex);
				setSearchWithoutDateORBlock(regex.test(value));
			}
			if (regex.test(value)) {
				numberOfCoins++;
				numberOfTokens = numberOfTokens + network.assetsNumber;
			}
		});
		setBlockchainInputValue(value);
		setSearchCriteria({
			coinAmount: numberOfCoins,
			tokensAmount: numberOfTokens,
		});

		if (
			value.length === 0 ||
			!rexesArray.some((value: boolean) => value === true)
		) {
			if (value.length === 0) {
				setErrorMsg("The address value is required.");
			} else {
				setErrorMsg(
					"Invalid address format. Please provide a valid address."
				);
			}
		} else {
			setErrorMsg("");
		}
	};
	useEffect(() => {
		if (buttonLocation.current) {
			const element =
				buttonLocation.current.getBoundingClientRect().top + window.scrollY;
			itemHeight(element);
		}
	}, []);
	useEffect(() => {
		setBlockchainInputValue(formData.walletAddress);
		setDateAndTime(formData.dateTime ? dayjs(formData.dateTime) : null);
		setTimeZone(formData.timeZone);
	}, [formData]);

	const [timeZone, setTimeZone] = useState(formData.timeZone);
	const sendRequest = () => {
		if (blockchainInputValue.length === 0) {
			setErrorMsg("The address value is required.");
		} else {
			const requestBOdy: BlockchainDataModel = {
				walletAddress: blockchainInputValue,
				timeZone: timeZone,
				searchCriteria: searchCriteria,
				requestQuerryParams: `?WalletAddress=${blockchainInputValue}&TimeZone=${timeZone}`,
				isLatestReq: true,
			};
			if (searchWithoutDateOrBlokc && !dateAndTime) {
				handleClick(TypeOfRequest.SearchByAdress, requestBOdy);
			} else {
				const dateForQp = dateAndTime
					? new Date(
							decodeURIComponent(
								dateAndTime?.format().replace(/,/g, " ")
							)
					  )
							.toISOString()
							.slice(0, 19)
							.replace("T", " ")
					: "";
				handleClick(TypeOfRequest.SearchByAdress, {
					walletAddress: blockchainInputValue,
					timeZone: timeZone,
					dateTime: dateAndTime?.format(),
					searchCriteria: searchCriteria,
					requestQuerryParams: `?WalletAddress=${blockchainInputValue}${
						dateAndTime ? `&dateAndTime={${dateForQp}}` : ""
					}&TimeZone=${timeZone}`,
					isLatestReq: dateAndTime?.format() ? false : true,
				});
			}
		}
	};

	const handleTimeZoneChange = (value: string) => {
		setTimeZone(value);
		setListOpen(false);
	};

	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: 200,
				width: 200,
				minWidth: 200,
			},
		},
	};

	const ITEM_HEIGHT = 48;
	const VISIBLE_ITEMS = 400; // Number of visible items in the dropdown

	// Calculate the total height of the list
	const totalHeight = Math.min(timeZones.length, VISIBLE_ITEMS) * ITEM_HEIGHT;

	// Render only the visible options using react-window's FixedSizeList
	const renderRow = useCallback(({ index, style }: any) => {
		return (
			<MenuItem
				key={index}
				value={timeZones[index]}
				style={style}
				onClick={() => handleTimeZoneChange(timeZones[index])}
			>
				{timeZones[index]}
			</MenuItem>
		);
		// eslint-disable-next-line
	}, []);

	const [openToast, setOpenToast] = useState(false);

	const handleCopyContent = () => {
		setOpenToast(true);
	};

	const handleCloseToast = (
		event: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}

		setOpenToast(false);
	};

	const actionCloseToast = (
		<IconButton
			size="small"
			aria-label="close"
			color="inherit"
			onClick={handleCloseToast}
		>
			x
		</IconButton>
	);
	return (
		<Typography
			component="article"
			sx={{
				borderBottom: {
					xs: "2px solid #0000001F",
					sm: "2px solid #0000001F",
					md: "0px",
				},
				order: 1,
				maxWidth: "500px",
				width: { sm: "100%", md: "45%" },
			}}
		>
			<Typography
				component="h2"
				sx={{ paddingBottom: "30px" }}
				id="balances-form"
			></Typography>
			<Box
				sx={{
					paddingTop: 0,
					width: "100%",
				}}
			>
				<Typography
					component="h3"
					variant="h1"
					sx={{
						paddingBottom: "1rem",
					}}
					id="balances-form"
				>
					{" "}
					Balances
				</Typography>

				<form>
					<Stack spacing={3} maxWidth={500}>
						<Box>
							{" "}
							<Snackbar
								open={openToast}
								autoHideDuration={2000}
								onClose={handleCloseToast}
								message="The address has been copied."
								action={actionCloseToast}
								sx={{ maxWidth: "300px" }}
							/>
							<Tooltip
								componentsProps={{
									tooltip: {
										sx: {
											borderRadius: "4px",
											border: "1px solid #808080",
											bgcolor: "#fff",
											color: "#757575",
											fontSize: "0.875rem",
											fontWeight: 500,
											"& .MuiTooltip-arrow": {
												color: "#fff",
											},
										},
									},
								}}
								title='To check the Balance on  Wallet Address using this form, you can copy and
								paste the following example address into the input field below and click the
								"Get Balances" button to see how the form works.'
								placement="top"
							>
								<Box onClick={onContentCopy}>
									<Typography id="address" variant="subtitle2">
										Example Address: <br />
									</Typography>

									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											gap: 1,
											alignContent: "center",
											width: "90%",
										}}
									>
										<Typography
											variant="subtitle2"
											ref={textAreaRef}
											sx={{
												cursor: "pointer",
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
												wordWrap: "break-word",
												maxWidth: {
													md: "unset",
													sm: "450px",
													xs: "250px",
													lg: "unset",
												},
											}}
										>
											0xA9D1e08C7793af67e9d92fe308d5697FB81d3E43{" "}
										</Typography>
										<ContentCopyOutlinedIcon
											sx={{ fontSize: "1.2rem", color: "#757575" }}
										/>
									</Box>
								</Box>
							</Tooltip>
						</Box>

						<TextField
							fullWidth
							autoComplete="off"
							label="Blockchain Wallet Address *"
							value={blockchainInputValue}
							id="blockchain-walletAddress-basic-input"
							variant="outlined"
							onChange={handleInputValue}
							onBlur={handleInputValue}
							error={errorMsg.length !== 0}
							helperText={errorMsg.length > 0 ? errorMsg : false}
							sx={{ paddingBottom: errorMsg.length === 0 ? "23px" : 0 }}
						/>

						<Box paddingBottom={5}>
							<Typography
								variant="h5"
								gutterBottom
								paddingBottom={4}
								marginTop={1}
							>
								Optional: Date, Time and Timezone
							</Typography>

							<Typography variant="subtitle2" gutterBottom>
								Leave the date blank to get the current balances (latest
								block). <br />
								Or, use the next form below to inquiry on specific block
								number.
							</Typography>

							<Grid
								container
								justifyContent={"space-between"}
								rowSpacing={2}
								paddingTop={4}
								sx={{
									marginBottom: {
										xs: 0,
										md: dateError ? "55px" : "78px",
									},
								}}
							>
								<Grid item xs={12} md={6} key="date-input">
									<FormControl fullWidth>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DateTimePicker
												ampm={false}
												onError={(error) => {
													error === "invalidDate"
														? setDateError(true)
														: setDateError(false);
												}}
												slotProps={{
													textField: {
														helperText: dateError
															? "Please input a valid format."
															: false,
													},
												}}
												defaultValue={dateAndTime}
												label="Select Date & Time"
												value={dateAndTime}
												viewRenderers={{
													hours: renderTimeViewClock,
													minutes: renderTimeViewClock,
													seconds: renderTimeViewClock,
												}}
												onChange={(newValue: any) =>
													setDateAndTime(newValue)
												}
											/>
										</LocalizationProvider>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={5} key="input-timeZone">
									<FormControl fullWidth>
										<InputLabel id="timeZone">Time Zone</InputLabel>
										<Select
											onClose={() => setListOpen(false)}
											open={isListOpen}
											onOpen={() => setListOpen(true)}
											input={
												<OutlinedInput
													label="time  Zone  "
													value=""
												/>
											}
											value={timeZone}
											renderValue={(selected) => {
												return selected ? selected : timeZone;
											}}
											MenuProps={{
												...MenuProps,
												anchorOrigin: {
													vertical: "bottom",
													horizontal: "left",
												},
												transformOrigin: {
													vertical: "top",
													horizontal: "left",
												},
											}}
										>
											<FixedSizeList
												height={totalHeight}
												width={250}
												itemSize={ITEM_HEIGHT}
												itemCount={timeZones.length}
											>
												{renderRow}
											</FixedSizeList>
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Box>

						<Grid
							container
							alignItems="center"
							sx={{
								flexDirection: {
									xs: "column",
									sm: "column",
									md: "row",
								},
							}}
						>
							<Grid item xs={12} md={5}>
								<Button
									disabled={errorMsg.length !== 0 || dateError}
									variant="contained"
									onClick={sendRequest}
									size="large"
									sx={{ fontSize: 13 }}
								>
									Get Balances
								</Button>
							</Grid>
							<Grid item xs={12} md={7}>
								<Typography variant="subtitle2" paddingY={"10px"}>
									for any/all: protocols, coins & tokens
								</Typography>
							</Grid>
						</Grid>
					</Stack>
				</form>
				<div ref={buttonLocation}></div>
			</Box>
		</Typography>
	);
};
