import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider } from "@emotion/react";
import { Theme } from "./utilities";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
const TRACKING_ID = "G-MXQCKPG13C"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

root.render(
	<ThemeProvider theme={Theme}>
		<React.StrictMode>
			<HelmetProvider>
				<BrowserRouter>
					<App />
				</BrowserRouter>
			</HelmetProvider>
		</React.StrictMode>
	</ThemeProvider>
);

reportWebVitals(() => {});
