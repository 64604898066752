export const GenericResponse = (genericProp: string) => {
	const response = {
		decimalPlaces: "",
		tokenImg: "",
		networkId: "",
		shortName: "",
		id: "",
		tokenBalance: "",
		amount: "",
		blockNumber: "",
		balance: genericProp,
		contractAddress: "",
		networkShortName: "",
		networkProviderName: "",
		unixTms: "",
		unixTimeUTC: "",
		source: "",
	};
	return response;
};
