import React from "react";
import { Helmet } from "react-helmet-async";
import { MetaTagsForSeo } from "../models/models";

export const Seo = ({ type, url }: MetaTagsForSeo) => {
	return (
		<Helmet>
			<title>
				ProtocolScout: multi-chain explorer optimized for auditors and
				accountants working with historical or current balances and transactions
				for stablecoins and other digital assets.{" "}
			</title>
			<link rel="canonical" href={url} />{" "}
		</Helmet>
	);
};
