import React from "react";
import { Seo } from "../utilities/index";
import { Typography, Box } from "@mui/material";
export const TermsOfService = () => {
	return (
		<Box
			sx={{
				margin: "0 auto",
				maxWidth: "1000px",
				padding: "16px",
				height: "100vh",
			}}
			component="main"
		>
			<Seo
				description="Your Gateway To Token Wallet Monitoring"
				title="Terms Of Service"
				type="website"
				url="https://protocolscout.com/terms-of-serice/"
			></Seo>
			<Typography component="section">
				<Typography
					variant="h1"
					gutterBottom
					sx={{
						padding: "20px 0",
						textAlign: "center",
					}}
				>
					Terms of Service
				</Typography>
				<Typography
					variant="h6"
					gutterBottom
					sx={{
						textAlign: "center",
					}}
				>
					Last update on September 10, 2023.
				</Typography>
				<Typography
					variant="h6"
					sx={{
						marginTop: 2,
					}}
				>
					We are in beta. <br />
					Actual terms will be updated soon.
					<br />
					For now, suffice to let you know that all data is provided as-is and
					without any guarantees. The data we provide is intended to be easily
					verifiable via 3rd parties so please click on links in the results and
					double check everything.
				</Typography>
			</Typography>
		</Box>
	);
};
