import React from "react";
import { Seo } from "../utilities/index";
import { Box, Typography, List, ListItem, Link } from "@mui/material";

export const AboutUs = () => {
	return (
		<Box
			component="main"
			style={{
				margin: "0 auto",
				maxWidth: "1000px",
				padding: "16px",
				minHeight: "100vh",
			}}
		>
			<Seo
				description="Your Gateway To Token Wallet Monitoring"
				title="About Us"
				type="website"
				url="https://protocolscout.com/about-us/"
			></Seo>
			<Box>
				<Typography
					component="h3"
					variant="h1"
					fontFamily="Ysabeau SC"
					textAlign="center"
					sx={{
						padding: "20px 0",
					}}
				>
					Functionality
				</Typography>
			</Box>
			<Box>
				<Typography component="p" fontSize="16px">
					ProtocolScout is a suite of blockchain tools optimized for use by
					financial auditors, accountants and professional service providers
					working with digital assets. Our suite includes the following
					functionalities:
				</Typography>
				<List sx={{ marginLeft: "30px" }}>
					<ListItem
						sx={{
							display: "list-item",
							listStyleType: "disc",
							paddingLeft: 0,
						}}
					>
						Balances
					</ListItem>
					<ListItem
						sx={{
							display: "list-item",
							listStyleType: "disc",
							paddingLeft: 0,
						}}
					>
						Transactions
					</ListItem>
					<ListItem
						sx={{
							display: "list-item",
							listStyleType: "disc",
							paddingLeft: 0,
						}}
					>
						Private key verification & xPub management
					</ListItem>
				</List>
				<Typography component="p" fontSize="16px">
					Balances & Transactions are generally available to the public via the
					so called (blockchain) explorers on sites like Etherscan.com or
					Solscan.io. However, ProtocolScout addresses many of their
					shortcomings because our suite is:
				</Typography>
				<List sx={{ marginLeft: "30px" }}>
					<ListItem
						sx={{
							display: "list-item",
							listStyleType: "disc",
							paddingLeft: 0,
						}}
					>
						<strong>Multi-protocol:</strong> we have combined access to most
						popular chains like Aptos, Algorand, Bitcoin, Ethereum, Solana,
						Tron, Polkadot, Cosmos etc.
					</ListItem>
					<ListItem
						sx={{
							display: "list-item",
							listStyleType: "disc",
							paddingLeft: 0,
						}}
					>
						<strong>Efficient to use:</strong> coins and tokens are processed
						simultaneously — users can specify the assets they wish to examine
						or they can let the “scout” automatically utilize our directory of
						known assets to deliver the maximum amount of relevant information.
					</ListItem>
					<ListItem
						sx={{
							display: "list-item",
							listStyleType: "disc",
							paddingLeft: 0,
						}}
					>
						<strong>Highly precise:</strong> users can get the latest
						information or they can “zoom” in on a specific block number or pick
						any timezone and a timestamp in the past that they wish to examine.
					</ListItem>
					<ListItem
						sx={{
							display: "list-item",
							listStyleType: "disc",
							paddingLeft: 0,
						}}
					>
						<strong> Transparent & verifiable: </strong>each data element
						retrieved from the blockchain is sourced from two providers (such as
						Ankr and Alchemy, or Infura and QuickNode) to guarantee the
						integrity of the results. Reports are delivered with all the details
						and are formatted to enable additional manual verification on public
						sites like Etherscan.com, Solscan.io etc.
					</ListItem>
				</List>
				<Typography component="p" fontSize="16px">
					ProtocolScout can be used as a primary source of blockchain data for
					financial reporting or it can be utilized as the secondary source for
					firms that have earlier scripting or automation around public sources.
					Our free beta is available for Balances and Transactions on-line at{" "}
					<Link href="https://protocolscout.com" target="_blank">
						https://protocolscout.com
					</Link>
					.
				</Typography>
			</Box>
			<Box>
				<Typography
					component="h3"
					variant="h1"
					fontFamily="Ysabeau SC"
					textAlign="center"
					sx={{
						padding: "20px 0",
					}}
				>
					Premium / Paid Access
				</Typography>
				<Typography
					component="p"
					fontSize="16px"
					sx={{
						paddingBottom: "20px",
					}}
				>
					ProtocolScout is an independent product and is built by AlgoMetric.
					Interested firms and individuals can license access to our SaaS or can
					contract us to deliver a private installation for use in-house. In
					such a case we can customize protocols, networks and configuration to
					utilize private blockchain nodes or any preferred data providers.
				</Typography>
			</Box>
		</Box>
	);
};
