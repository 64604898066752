import React from "react";
import { Seo } from "../utilities/index";
import { Typography, Box } from "@mui/material";
export const PrivacyPolicy = () => {
	return (
		<Box
			component="main"
			sx={{
				margin: "0 auto",
				maxWidth: "1000px",
				padding: "16px",
				height: "100vh",
			}}
		>
			<Seo
				description="Your Gateway To Token Wallet Monitoring"
				title="Privacy Policy"
				type="website"
				url="https://protocolscout.com/privacy-policy/"
			></Seo>
			<Typography component="section">
				<Typography
					variant="h1"
					gutterBottom
					sx={{
						padding: "20px 0",
						textAlign: "center",
					}}
				>
					Privacy Policy
				</Typography>
				<Typography
					variant="h6"
					gutterBottom
					sx={{
						textAlign: "center",
					}}
				>
					Last update on September 10, 2023.
				</Typography>
				<Typography
					variant="h6"
					sx={{
						marginTop: 2,
					}}
				>
					We are in beta. <br />
					Actual terms will be updated soon. <br />
					For now, suffice to let you know that we don' t track usage besides
					the standard Google / Analytics.
				</Typography>
			</Typography>
		</Box>
	);
};
