import { Box, Typography } from "@mui/material";
import React from "react";
import { Seo } from "../utilities";

export const PageNotFound = () => {
	return (
		<Box
			component={"main"}
			sx={{ minHeight: "100vh", display: "flex", justifyContent: "center" }}
		>
			<Seo
				description="Your Gateway To Token Wallet Monitoring"
				title="404 Not Found"
				type="website"
				url="https://protocolscout.com/contact-us/"
			></Seo>
			<Typography sx={{ paddingTop: "2rem" }}> Page not found</Typography>
		</Box>
	);
};
