import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	TextField,
	Button,
	Stack,
	Grid,
	FormControl,
	Select,
	Typography,
	InputLabel,
	Box,
	MenuItem,
	OutlinedInput,
	Tooltip,
	IconButton,
	Snackbar,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Dayjs } from "dayjs";
import {
	HandleRequestTransactionsProps,
	NetworkModal,
	TypeOfRequest,
} from "../../models/models";
import {
	LocalizationProvider,
	DateTimePicker,
	renderTimeViewClock,
} from "@mui/x-date-pickers";

import { FixedSizeList } from "react-window";
import { TransactionRequest } from "../../models/transactions";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

export const TransactionsFrom: React.FC<HandleRequestTransactionsProps> = ({
	browserTimeZone,
	timeZones,
	handleClickOnTransactions,
	itemHeight,
	listOfNetworks,
}) => {
	const [blockchainInputValue, setBlockchainInputValue] = useState<string>("");
	const [error, setError] = useState<boolean>(false);
	const [startDateAndTime, setStartDateAndTime] = useState<Dayjs | null>(null);
	const [startDateError, setStartDateError] = useState(false);
	const [endDateAndTime, setEndDateAndTime] = useState<Dayjs | null>(null);
	const [endDateError, setEndDateError] = useState<boolean>(false);
	const [isListOpen, setListOpen] = useState<boolean>(false);
	const [isEndTimeZoneListOpen, setEndTimeZoneLIst] = useState<boolean>(false);
	const [startTimeZone, setStartTimeZone] = useState<string>(browserTimeZone);
	const [endTimeZone, setEndTimeZone] = useState<string>(browserTimeZone);
	const textAreaRef = useRef<HTMLElement | null>(null);
	const buttonLocation = useRef<HTMLDivElement | null>(null);

	const [searchWithoutDateOrBlokc, setSearchWithoutDateORBlock] =
		useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>("");
	const [isTransactionByHash, setIsTransactionByHash] =
		useState<boolean>(false);

	const handleInputValue = (e: any) => {
		setBlockchainInputValue(e.target.value);
		const value = e.target.value;
		const regexArray: boolean[] = [];
		listOfNetworks?.map((network: NetworkModal): void => {
			const regex = new RegExp(network.walletValidationRegex);
			regexArray.push(regex.test(value));
			if (network.name === "Tron") {
				const regex = new RegExp(network.walletValidationRegex);
				setSearchWithoutDateORBlock(regex.test(value));
			}
		});
		const transactionRegex = new RegExp(/^[0-9a-fA-F]{64}$/);

		setBlockchainInputValue(value);
		regexArray.push(transactionRegex.test(value));
		setIsTransactionByHash(transactionRegex.test(value));
		if (
			value.length === 0 ||
			!regexArray.some((value: boolean) => value === true)
		) {
			if (value.length === 0) {
				setErrorMsg("The address / transaction hash value is required.");
			} else {
				setErrorMsg(
					"Invalid address / transaction has format. Please provide a valid value."
				);
			}
		} else {
			setErrorMsg("");
		}
		if (e.target.value.length === 0) {
			setError(true);
		} else {
			setError(false);
		}
	};

	const sendRequest = () => {
		if (blockchainInputValue.length === 0) {
			setError(true);
		} else {
			const requestObject: TransactionRequest = {
				endTimeZone: endTimeZone,
				startTimeZone: startTimeZone,
				walletAddress: blockchainInputValue,
			};
			if (startDateAndTime !== null) {
				requestObject.startDate = startDateAndTime.format();
			}

			if (endDateAndTime !== null) {
				requestObject.endDate = endDateAndTime.format();
			}

			handleClickOnTransactions(
				TypeOfRequest.SearchByAdress,
				requestObject,
				isTransactionByHash
			);
		}
	};

	const handleStartTimeZoneChange = (value: string) => {
		setStartTimeZone(value);
		setListOpen(false);
	};
	const handleEndTimeZoneChange = (value: string) => {
		setEndTimeZone(value);
		setEndTimeZoneLIst(false);
	};
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: 200,
				width: 200,
				minWidth: 200,
			},
		},
	};

	const ITEM_HEIGHT = 48;
	const VISIBLE_ITEMS = 400;

	const totalHeight = Math.min(timeZones.length, VISIBLE_ITEMS) * ITEM_HEIGHT;

	const renderRow = useCallback(({ index, style }: any) => {
		return (
			<MenuItem
				key={index}
				value={startTimeZone[index]}
				style={style}
				onClick={() => handleStartTimeZoneChange(timeZones[index])}
			>
				{timeZones[index]}
			</MenuItem>
		);
		// eslint-disable-next-line
	}, []);
	const renderEndRow = useCallback(({ index, style }: any) => {
		return (
			<MenuItem
				key={index}
				value={endTimeZone[index]}
				style={style}
				onClick={() => handleEndTimeZoneChange(timeZones[index])}
			>
				{timeZones[index]}
			</MenuItem>
		);
		// eslint-disable-next-line
	}, []);

	const [openToast, setOpenToast] = useState(false);

	const handleCopyContent = () => {
		setOpenToast(true);
	};

	const handleCloseToast = (
		event: React.SyntheticEvent | Event,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return;
		}

		setOpenToast(false);
	};

	const actionCloseToast = (
		<>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleCloseToast}
			>
				x
			</IconButton>
		</>
	);
	const onContentCopy = (e: any) => {
		if (textAreaRef.current) {
			const textToCopy = textAreaRef.current.textContent;
			const tempInput = document.createElement("input");
			document.body.appendChild(tempInput);
			tempInput.value = textToCopy?.trim() || "";
			tempInput.select();
			document.execCommand("copy");
			document.body.removeChild(tempInput);

			handleCopyContent();
		}
	};
	useEffect(() => {
		if (buttonLocation.current) {
			const element =
				buttonLocation.current.getBoundingClientRect().top + window.scrollY;
			itemHeight(element);
		}
	}, []);

	return (
		<Typography
			component="article"
			sx={{
				maxWidth: "500px",

				width: { sm: "100%", md: "45%" },
				order: { xs: 3, sm: 3, md: 2, lg: 2 },
			}}
		>
			<Box>
				<Typography
					component="h3"
					sx={{ paddingBottom: "30px" }}
					id="transactions-form"
				></Typography>
				<Typography
					component="h3"
					variant="h1"
					sx={{
						paddingBottom: "1rem",
					}}
					id="balances-form"
				>
					{" "}
					Transactions
				</Typography>
				<form>
					<Stack spacing={3} maxWidth={500}>
						<Box>
							{" "}
							<Snackbar
								open={openToast}
								autoHideDuration={2000}
								onClose={handleCloseToast}
								message="The Address has been copied."
								action={actionCloseToast}
								sx={{ maxWidth: "300px" }}
							/>{" "}
							<Tooltip
								componentsProps={{
									tooltip: {
										sx: {
											fontSize: "0.875rem",
											fontWeight: 500,
											borderRadius: "4px",
											border: "1px solid #808080",
											bgcolor: "#fff",
											color: "#000",
											"& .MuiTooltip-arrow": {
												color: "#fff",
											},
										},
									},
								}}
								title='To check the Transactions on  Wallet Address using this form, you can copy and
							paste the following example address into the input field below and click the
							"Get Transactions" button to see how the form works.'
								placement="top"
							>
								<Box onClick={onContentCopy}>
									<Typography id="address" variant="subtitle2">
										Example Address: <br />
									</Typography>

									<Box
										sx={{
											display: "flex",
											flexDirection: "row",
											gap: 1,
											alignContent: "center",
											width: "90%",
										}}
									>
										<Typography
											variant="subtitle2"
											ref={textAreaRef}
											sx={{
												cursor: "pointer",
												whiteSpace: "nowrap",
												overflow: "hidden",
												textOverflow: "ellipsis",
												wordWrap: "break-word",
												maxWidth: {
													md: "80%",
													sm: "80%",
													xs: "250px",
													lg: "unset",
												},
											}}
										>
											34xp4vRoCGJym3xR7yCVPFHoCNxv4Twseo{" "}
										</Typography>
										<ContentCopyOutlinedIcon
											sx={{ fontSize: "1.2rem", color: "#757575" }}
										/>
									</Box>
								</Box>
							</Tooltip>
						</Box>
						<TextField
							fullWidth
							autoComplete="off"
							label="Blockchain Wallet Address or Transaction Hash *"
							value={blockchainInputValue}
							id="transaction-address-hash-input-field"
							variant="outlined"
							onChange={handleInputValue}
							onBlur={handleInputValue}
							error={errorMsg.length !== 0}
							helperText={errorMsg.length > 0 ? errorMsg : false}
							sx={{ paddingBottom: errorMsg.length <= 0 ? "23px" : 0 }}
						/>

						<Box>
							<Grid
								sx={{
									display: "flex",
									flexDirection: { xs: "column", md: "row" },
									margin: 0,
									gap: 0.5,

									paddingBottom: 4,
									marginTop: 1,
								}}
							>
								<Typography variant="h5" gutterBottom margin={0}>
									Optional: Date Range, Time and Timezone
								</Typography>
							</Grid>

							<Typography variant="subtitle2" gutterBottom>
								Leave the date blank to get all the transactions (latest
								block). <br />
								Or, use the next form below to narrow your search
								criteria.
							</Typography>

							<Grid
								container
								justifyContent={"space-between"}
								rowSpacing={2}
								columnSpacing={2}
								paddingTop={4}
							>
								<Grid
									item
									xs={12}
									md={7}
									key="date-input"
									sx={{ paddingBottom: startDateError ? 0 : "23px" }}
								>
									<FormControl fullWidth>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DateTimePicker
												ampm={false}
												onError={(error) => {
													error === "invalidDate"
														? setStartDateError(true)
														: setStartDateError(false);
												}}
												slotProps={{
													textField: {
														helperText: startDateError
															? " Please input a valid format."
															: false,
													},
												}}
												defaultValue={startDateAndTime}
												label="Select Start Date & Time"
												viewRenderers={{
													hours: renderTimeViewClock,
													minutes: renderTimeViewClock,
													seconds: renderTimeViewClock,
												}}
												onChange={(newValue: any) =>
													setStartDateAndTime(newValue)
												}
											/>
										</LocalizationProvider>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={5} key="input-timeZone-start">
									<FormControl fullWidth>
										<InputLabel id="timeZone">
											Start Time Zone
										</InputLabel>
										<Select
											onClose={() => setListOpen(false)}
											open={isListOpen}
											onOpen={() => setListOpen(true)}
											displayEmpty
											input={
												<OutlinedInput label="Start Time Zone" />
											}
											value={startTimeZone}
											renderValue={(selected) =>
												selected ? selected : startTimeZone
											}
											MenuProps={{
												...MenuProps,
												anchorOrigin: {
													vertical: "bottom",
													horizontal: "left",
												},
												transformOrigin: {
													vertical: "top",
													horizontal: "left",
												},
											}}
										>
											<FixedSizeList
												height={totalHeight}
												width={250}
												itemSize={ITEM_HEIGHT}
												itemCount={timeZones.length}
											>
												{renderRow}
											</FixedSizeList>
										</Select>
									</FormControl>
								</Grid>
								<Grid
									item
									xs={12}
									md={7}
									key="date-input-end"
									sx={{ paddingBottom: endDateError ? 0 : "23px" }}
								>
									<FormControl fullWidth>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DateTimePicker
												ampm={false}
												onError={(error) => {
													error === "invalidDate"
														? setEndDateError(true)
														: setEndDateError(false);
												}}
												slotProps={{
													textField: {
														helperText: endDateError
															? " Please input a valid format."
															: false,
													},
												}}
												defaultValue={endDateAndTime}
												label="Select End Date & Time"
												viewRenderers={{
													hours: renderTimeViewClock,
													minutes: renderTimeViewClock,
													seconds: renderTimeViewClock,
												}}
												onChange={(newValue: any) =>
													setEndDateAndTime(newValue)
												}
											/>
										</LocalizationProvider>
									</FormControl>
								</Grid>
								<Grid item xs={12} md={5} key="input-timeZone-end">
									<FormControl fullWidth>
										<InputLabel id="endTimeZone">
											End Time Zone
										</InputLabel>
										<Select
											onClose={() => setEndTimeZoneLIst(false)}
											open={isEndTimeZoneListOpen}
											onOpen={() => setEndTimeZoneLIst(true)}
											displayEmpty
											input={<OutlinedInput label="End Time Zone" />}
											value={endTimeZone}
											renderValue={(selected) =>
												selected ? selected : endTimeZone
											}
											MenuProps={{
												...MenuProps,
												anchorOrigin: {
													vertical: "bottom",
													horizontal: "left",
												},
												transformOrigin: {
													vertical: "top",
													horizontal: "left",
												},
											}}
										>
											<FixedSizeList
												height={totalHeight}
												width={250}
												itemSize={ITEM_HEIGHT}
												itemCount={timeZones.length}
											>
												{renderEndRow}
											</FixedSizeList>
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Box>

						<Grid
							container
							alignItems="center"
							sx={{
								flexDirection: {
									xs: "column",
									sm: "column",
									md: "row",
								},
							}}
						>
							<Grid item xs={12} md={5}>
								<Button
									disabled={error || startDateError || endDateError}
									variant="contained"
									onClick={sendRequest}
									size="large"
									sx={{ fontSize: 13, padding: "8px 10px" }}
								>
									Get Transactions
								</Button>
							</Grid>
							<Grid item xs={12} md={7}>
								<Typography
									variant="subtitle2"
									marginLeft={1}
									paddingY={"10px"}
								>
									for any/all: protocols, coins & tokens
								</Typography>
							</Grid>
						</Grid>
					</Stack>
				</form>
				<div ref={buttonLocation}></div>
			</Box>
		</Typography>
	);
};
