import { TypeOfTableData } from "../models/models";

export const sorter = (object: any, typeOfTable: TypeOfTableData): any => {
	const dataWithValues = object
		.filter((data: any) => {
			return data.balance > 0;
		})
		.sort((a: any, b: any): any => {
			return +b.balance - +a.balance;
		});
	const premium = object.filter((data: any) => {
		return (
			data.balance ===
			"Supported in the premium version only \u2014 please contact us"
		);
	});
	const resultWithEmptyData = object
		.filter((data: any) => {
			return data.balance === 0;
		})
		.sort((a: any, b: any) => {
			return a.name.localeCompare(b.name);
		});

	return [...dataWithValues, ...premium, ...resultWithEmptyData];
};
