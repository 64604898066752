import React from "react";
import { Footer, Navigation } from "./components/Layouts/index";
import {
	HomePage,
	AboutUs,
	PrivacyPolicy,
	TermsOfService,
	PageNotFound,
} from "./pages/index";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { Theme } from "./utilities";

function App() {
	return (
		<ThemeProvider theme={Theme}>
			<>
				<Navigation />
				<>
					<Routes>
						<Route path="/" element={<HomePage />} />
						<Route path="/:group/" element={<HomePage />}>
							<Route
								path=":typeOfForm/:address/"
								element={<HomePage />}
							/>
						</Route>
						<Route
							path="/transactionsByHash/:hash/"
							element={<HomePage />}
						/>
						<Route path="/about-us/" element={<AboutUs />} />
						<Route path="/privacy-policy/" element={<PrivacyPolicy />} />
						<Route
							path="/terms-of-service/"
							element={<TermsOfService />}
						/>
						<Route path="*" element={<PageNotFound />} />
					</Routes>
				</>
				<Footer />
			</>
		</ThemeProvider>
	);
}

export default App;
