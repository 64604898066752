import { SingleNetworkCoin } from "./SingleNetworkcoin";

import { NetworkModal, NetworkCoinstProps } from "../../models/models";
import React, { useEffect, useState } from "react";

import {
	CircularProgress,
	Grid,
	Link,
	Stack,
	Typography,
	useTheme,
} from "@mui/material";

export const NetworkCoinsWrapper: React.FC<NetworkCoinstProps> = ({
	network,
	error,
}) => {
	const theme = useTheme();

	let currentLocation = window.location.origin;
	let iconPath = currentLocation.includes("localhost")
		? process.env.REACT_APP_IMG_PATH
		: currentLocation + "/";

	const [tokenNumber, setTokenNumber] = useState(0);

	useEffect(() => {
		const totalTokens = network.reduce(
			(acc, network) => acc + network.assetsNumber,
			0
		);

		setTokenNumber(totalTokens);
	}, [network]);

	return (
		<Stack
			component="section"
			sx={{
				height: "100%",
				width: "100%",
				padding: 3,
			}}
		>
			<Stack
				spacing={3}
				sx={{
					height: { xs: "300px", sm: "300px", md: "430px" },
					width: "100%",
					maxWidth: "1200px",
					marginX: "auto",
				}}
			>
				<Typography
					variant="body2"
					sx={{
						marginTop: 2,
					}}
				>
					Browse our {network.length} protocols and their {""}
					{tokenNumber} assets
				</Typography>

				<Grid
					container
					component={"article"}
					sx={{
						overflowY: "auto",
						width: "100%",
						marginX: "auto",
						display: "flex",
						gap: 3.5,
						rowGap: 0,
						justifyContent: { xs: "flex-start", md: "center" },
						paddingY: 2,
						paddingRight: 2,
						paddingLeft: { xs: "15px", md: 0 },
					}}
				>
					{network
						.slice()
						.sort((a: NetworkModal, b: NetworkModal) => {
							return a.protocolName.localeCompare(b.protocolName);
						})
						.map((networks: NetworkModal) => {
							return (
								<Grid
									item
									key={networks.name + "grid"}
									xs={12}
									sm={6}
									md={2.5}
								>
									<SingleNetworkCoin
										data={networks}
										key={networks.name}
										url={iconPath || ""}
										explorerUrl={
											networks.walletAddressExplorerUrlTemplate
										}
									/>
								</Grid>
							);
						})}
					{tokenNumber === 0 && error.length === 0 && (
						<Grid
							sx={{
								width: "100%",
								maxHeight: "210px",
								margin: "auto",
								minHeight: "80px",
								display: "flex",
								height: "100%",
								justifyContent: "center",
							}}
						>
							<CircularProgress size="80px" />
						</Grid>
					)}
					{error.length > 0 && (
						<Typography
							variant="h5"
							sx={{
								marginX: "auto",

								color: theme.palette.error.main,
							}}
						>
							{error}
						</Typography>
					)}
				</Grid>
				<Typography
					variant="body2"
					gutterBottom
					sx={{
						paddingTop: 3,
						width: "100%",
						maxWidth: "1200px",
						marginX: "auto",
					}}
				>
					Alternatively, use the customized{" "}
					<Link href="#specialized-balances-form">balances</Link> and{" "}
					<Link href="#specialized-transactions-form">transactions</Link>{" "}
					forms further below to inquiry on any other standardized tokens
					or specific blocks.
				</Typography>
			</Stack>
		</Stack>
	);
};
