import { NetworkCoinToken, SingleNetworkCoinsProps } from "../../models/models";
import React, { useState } from "react";

import Typography from "@mui/material/Typography";
import {
	Avatar,
	Box,
	Modal,
	Button,
	Grid,
	Link,
	CircularProgress,
	useTheme,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { api } from "../../utilities/index";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ReactGA from "react-ga4";

export const SingleNetworkCoin: React.FC<SingleNetworkCoinsProps> = ({
	data,
	explorerUrl,
}) => {
	const theme = useTheme();
	const [loaderIsAcive, setLoaderIsActive] = useState<boolean>(false);
	const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);
	let currentLocation = window.location.origin;
	let iconPath = currentLocation.includes("localhost")
		? process.env.REACT_APP_IMG_PATH
		: currentLocation + "/";

	const modalStyle = {
		position: "absolute" as "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 475,
		width: isMobileDevice ? "90%" : "70%",
		height: "90%",
		maxHeight: "752px",
		bgcolor: "background.paper",
		borderRadius: "3px",
		outline: "none",
		overflow: "hidden",
		display: "flex",
		flexDirection: "column",
		padding: isMobileDevice ? "1rem 0.5rem" : "1rem",
	};

	const headerStyle = {
		flex: "0 0 auto",
		padding: "0 8px 16px",
		display: "flex",
		flexDirection: "column",
		maxHeight: "90vh",
		Height: "100%",
	};

	const bodyStyle = {
		flex: "1 1 auto",
		overflowY: "auto",
		padding: 2,
		display: "flex",
		flexDirection: "column",
		gap: 3,
		height: "auto",
	};

	const footerStyle = {
		flex: "0 0 auto",
		display: "flex",
		justifyContent: "center",
		paddingTop: "20px",
		position: "sticky",
		bottom: "10px",
	};
	const [open, setOpen] = useState(false);
	const [networkTokens, setNetworkTokens] = useState<NetworkCoinToken[]>([]);
	const [errorMsg, setErrorMsg] = useState<string>("");

	const handleOpen = (id: number, name: string) => {
		setOpen(true);
		console.log(name);

		const eventOptions = {
			category: "Modal",
			action: "Click_on_modal",
			label: name,
			event_category: "Category",
			event_action: "Action",
			network_name: name,
			network_key: id,
		};
		ReactGA.event("Click_on_modal", eventOptions);
		getAllTokens(id);
	};

	const handleClose = () => setOpen(false);

	const getAllTokens = async (id: number) => {
		setLoaderIsActive(true);
		try {
			const response = await api.get(`get-assets?NetworkId=${id}`, {
				headers: {
					"Cache-Control": "public, max-age=3600",
				},
			});
			setLoaderIsActive(false);
			const { data } = response;
			setNetworkTokens(data.assets);
		} catch (error: any) {
			setErrorMsg(error.message);
			console.log(error);
		}
	};

	return (
		<>
			<Modal open={open} onClose={handleClose}>
				<Box sx={modalStyle}>
					<Box
						sx={{
							...headerStyle,
							marginLeft: "auto",
							cursor: "pointer",
						}}
					>
						<CloseOutlinedIcon
							onClick={handleClose}
							sx={{ marginLeft: "auto" }}
						/>
					</Box>
					<Box
						sx={{
							...headerStyle,
							textAlign: "center",
							fontSize: "22px",
							fontWeight: 400,
						}}
					>
						{data.name}
					</Box>

					<Box
						sx={{
							...headerStyle,
							paddingTop: 2,
							padding: "1rem",
							fontWeight: 400,
						}}
					>
						<Typography
							sx={{
								textAlign: "left",
								fontSize: "1rem",
								lineHeight: "1.8rem",
							}}
						>
							{" "}
							The native asset{" "}
							{data.assetsNumber > 1 ? "and tokens below" : ""} will be
							included automatically in all inquiries for blockchain
							balances or transactions.
						</Typography>
						<Typography
							sx={{
								paddingTop: 1,
								textAlign: "left",
								fontSize: "1rem",
								lineHeight: "1.8rem",
							}}
						>
							Moreover, you can inquiry on other assets in the customized
							forms on the bottom of the page.
						</Typography>
					</Box>

					<Box sx={bodyStyle}>
						{networkTokens.length !== 0 &&
							networkTokens
								.slice()
								.sort((a: NetworkCoinToken, b: NetworkCoinToken) => {
									return a.name.localeCompare(b.name);
								})
								.sort((a: NetworkCoinToken, b: NetworkCoinToken) => {
									return a.shortName.localeCompare(b.shortName);
								})
								.sort((a: NetworkCoinToken, b: NetworkCoinToken) => {
									return a.isNativeAsset === b.isNativeAsset
										? 0
										: a.isNativeAsset
										? -1
										: 1;
								})
								.map((token: NetworkCoinToken) => {
									let urlRoute = "";
									let truncAddress = "";
									let address = "";
									let hrefLink = "";

									if (
										token.contractAddress &&
										explorerUrl &&
										token.contractAddress !== "_"
									) {
										hrefLink = explorerUrl.replace(
											"SMART_CONTRACT_ADDRESS",
											token.contractAddress
										);
										urlRoute = explorerUrl.split("/")[2];
										let numberForTrunc =
											urlRoute.length +
											token.contractAddress.length -
											40;
										if (numberForTrunc > 0) {
											truncAddress =
												token.contractAddress.substring(
													0,
													isMobileDevice ? 4 : 8
												) +
												"..." +
												token.contractAddress.slice(
													isMobileDevice ? -4 : -8,
													token.contractAddress.length
												);
										} else {
											truncAddress = token.contractAddress;
										}

										address = urlRoute + "/" + truncAddress;
									}

									return (
										<Grid
											item
											component="article"
											key={token.name + "grid" + token.id}
										>
											<Grid
												container
												sx={{
													display: "flex",
													gap: 1,
													flexDirection: "column",
												}}
											>
												<Grid
													item
													sx={{
														display: "flex",
														flexDirection: "row",
														gap: 2,
														alignItems: "center",
													}}
												>
													<Avatar
														sx={{ width: "30px", height: "30px" }}
														src={`${iconPath}${token.iconPath.slice(
															0,
															-3
														)}svg`}
														alt={token.name + " asset logo"}
													></Avatar>{" "}
													<Typography>
														{" "}
														{token.shortName.toLowerCase() ===
														token.name.toLowerCase()
															? token.shortName
															: token.shortName +
															  " / " +
															  token.name}
													</Typography>
												</Grid>
												<Grid
													item
													sx={{
														Width: "420px",
														maxWidth: "92%",
														color: "#5a5a5a",
													}}
												>
													{address.length > 0 &&
													!token.isNativeAsset ? (
														<Link
															sx={{
																textDecoration: "none",
																color: "#5a5a5a",
																whiteSpace: "pre-wrap",
																overflowWrap: "anywhere",
																display: "flex",
																alignItems: "center",
																width: "100%",
																fontSize: isMobileDevice
																	? "0.875rem"
																	: "1rem",
															}}
															href={hrefLink}
															target="_blank"
														>
															{address}
															<OpenInNewIcon
																sx={{
																	width: "16px",
																	height: "16px",
																	paddingLeft: 1,
																}}
															/>
														</Link>
													) : null}
													{token.isNativeAsset && (
														<span>Native Asset</span>
													)}
												</Grid>
											</Grid>
										</Grid>
									);
								})}
						{loaderIsAcive && (
							<Box
								sx={{ display: "flex", margin: "auto" }}
								alignItems={"center"}
								justifyContent={"center"}
								height="300px"
							>
								<CircularProgress size="100px" />
							</Box>
						)}
						{errorMsg.length > 0 && (
							<Typography
								variant="h5"
								sx={{
									marginX: "auto",

									color: theme.palette.error.main,
								}}
							>
								{errorMsg}
							</Typography>
						)}
					</Box>

					<Box sx={footerStyle}>
						<Button onClick={handleClose} variant="contained">
							OK
						</Button>
					</Box>
				</Box>
			</Modal>

			<Grid
				container
				padding={0}
				sx={{
					marginBottom: 4,
					minWidth: "170px",
					display: "flex",
					flexDirection: "column",
					backgroundColor: "transparent",
					width: {
						xs: "100%",
					},
				}}
			>
				<Grid
					item
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-start",
						alignItems: "center",
						gap: 2,
					}}
				>
					<Avatar
						src={`${iconPath}${data.iconPath.slice(0, -3)}svg`}
						alt={data.name + "asset logo"}
					/>

					<Typography
						variant="h5"
						padding={0}
						display="flex"
						alignItems={"center"}
					>
						{data.protocolName}
						<InfoOutlinedIcon
							sx={{
								paddingLeft: "0.5rem",
								cursor: "pointer",
								display: { xs: "block", sm: "none" },
							}}
							onClick={() => handleOpen(data.id, data.protocolName)}
						></InfoOutlinedIcon>
					</Typography>
				</Grid>

				<Button
					variant={"contained"}
					sx={{
						margin: " 10px  0",
						minWidth: { sm: "170px" },
						width: "100%",
						minHeight: "2.5rem",
						display: { xs: "none", sm: "block" },
					}}
					onClick={() => handleOpen(data.id, data.protocolName)}
				>
					View {""}
					{data.assetsNumber > 1 ? (
						<span
							style={{
								padding: "4px 4px 0",
								fontFamily: "Roboto",
								fontSize: "0.8rem",
							}}
						>
							{data.assetsNumber}
						</span>
					) : (
						false
					)}
					{data.assetsNumber === 1 ? "Details" : ""}
					{data.assetsNumber > 1 ? "Assets" : ""}
				</Button>
			</Grid>
		</>
	);
};
