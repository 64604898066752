import imgLogo from "../../assets/images/logo-with-text.svg";
import React, { useState, useEffect } from "react";
import {
	AppBar,
	Toolbar,
	IconButton,
	Box,
	List,
	ListItem,
	Drawer,
	useMediaQuery,
	useTheme,
	Button,
	Typography,
	Badge,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { load } from "recaptcha-v3";

export function Navigation() {
	const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;
	const location = useLocation();
	const navigate = useNavigate();
	const theme = useTheme();
	const isXsScreen = useMediaQuery(theme.breakpoints.only("xs"));
	const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [pathName, setPathName] = useState(location.pathname);

	useEffect(() => {
		setPathName(location.pathname);
		setRecaptcha(location.pathname);
		// eslint-disable-next-line
	}, [location.pathname]);
	const handleMobileMenuOpen = () => {
		setMobileMenuOpen(true);
	};
	const setRecaptcha = async (location: string) => {
		const isMobileDevice = /Mobi|Android/i.test(navigator.userAgent);

		if (isMobileDevice) {
			return;
		}

		const recaptcha = await load(`${recaptchaKey}`);
		if (location !== "/all/") {
			recaptcha.hideBadge();
		} else {
			recaptcha.showBadge();
		}
	};

	const handleMobileMenuClose = () => {
		setMobileMenuOpen(false);
	};

	return (
		<AppBar
			position="static"
			sx={{
				flexGrow: 1,
				padding: "8px 24px",
				alignItems: "center",
				boxShadow: "none",
				position: "sticky",
				top: 0,
				zIndex: 2,
			}}
		>
			<Toolbar
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					maxHeight: "200px",
					maxWidth: "1200px",
					width: "100%",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						flexBasis: { xs: "auto", sm: "auto" },
						width: { xs: "200px", sm: "250px" },
					}}
				>
					<Badge badgeContent={"Beta"} color="primary" sx={{ width: "100%" }}>
						<Link to="/">
							<img
								style={{
									height: "40px",
									width: "100%",
									margin: "auto 0",
									padding: "0",
								}}
								src={imgLogo}
								alt="logo-protocol-scout"
							/>
						</Link>
					</Badge>
				</Box>

				<IconButton
					edge="start"
					color="inherit"
					aria-label="menu"
					onClick={handleMobileMenuOpen}
					sx={{
						display: { xs: "block", sm: "block", md: "none" },
						marginLeft: "10px",
						marginTop: 1,
					}}
				>
					<MenuIcon />
				</IconButton>

				<Box sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
					<Button
						color="inherit"
						disabled={pathName === "/" || pathName === "/all/"}
						sx={{ marginLeft: "10px" }}
						onClick={() => {
							navigate("/");
						}}
					>
						<Typography fontFamily="Ysabeau SC" fontWeight={700} variant="h6">
							Home
						</Typography>
					</Button>
					<Button
						disabled={pathName === "/about-us/"}
						color="inherit"
						sx={{ marginLeft: "10px" }}
						onClick={() => {
							navigate("/about-us/");
						}}
					>
						<Typography fontFamily="Ysabeau SC" fontWeight={700} variant="h6">
							About Us
						</Typography>
					</Button>
					<Button
						color="inherit"
						disabled={pathName === "/contact-us/"}
						sx={{ marginLeft: "10px" }}
					>
						<Typography fontFamily="Ysabeau SC" fontWeight={700} variant="h6">
							<Link
								to="https://www.linkedin.com/in/sinisaradovcic/"
								target="_blank"
								style={{ textDecoration: "none", color: "inherit" }}
							>
								CONTACT US
							</Link>
						</Typography>
					</Button>
				</Box>
			</Toolbar>

			<Drawer
				anchor={isXsScreen ? "left" : "right"}
				open={isMobileMenuOpen}
				onClose={handleMobileMenuClose}
			>
				<Box
					role="presentation"
					onClick={handleMobileMenuClose}
					onKeyDown={handleMobileMenuClose}
					width="200px"
				>
					<List>
						<ListItem sx={{ marginLeft: "10px" }}>
							<Button
								color="inherit"
								disabled={pathName === "/"}
								sx={{ marginLeft: "10px" }}
								onClick={() => {
									navigate("/");
								}}
							>
								<Typography
									fontFamily="Ysabeau SC"
									fontWeight={700}
									variant="h6"
								>
									Home
								</Typography>
							</Button>
						</ListItem>
						<ListItem sx={{ marginLeft: "10px" }}>
							<Button
								color="inherit"
								disabled={pathName === "/about-us/"}
								sx={{ marginLeft: "10px" }}
								onClick={() => {
									navigate("/about-us/");
								}}
							>
								<Typography
									fontFamily="Ysabeau SC"
									fontWeight={700}
									variant="h6"
								>
									About Us
								</Typography>
							</Button>
						</ListItem>
						<ListItem sx={{ marginLeft: "10px" }}>
							<Button
								color="inherit"
								sx={{ marginLeft: "10px" }}
								disabled={pathName === "/contact-us/"}
							>
								<Typography
									fontFamily="Ysabeau SC"
									fontWeight={700}
									variant="h6"
								>
									<Link
										to="https://www.linkedin.com/in/sinisaradovcic/"
										target="_blank"
										style={{ textDecoration: "none", color: "inherit" }}
									>
										CONTACT US
									</Link>
								</Typography>
							</Button>
						</ListItem>
					</List>
				</Box>
			</Drawer>
		</AppBar>
	);
}
