import React, { useCallback, useEffect, useRef, useState } from "react";
import {
	TextField,
	Button,
	FormControl,
	Select,
	MenuItem,
	Grid,
	Stack,
	Typography,
	InputLabel,
	Box,
	FormHelperText,
	Autocomplete,
	Divider,
	OutlinedInput,
} from "@mui/material";
import {
	LocalizationProvider,
	DateTimePicker,
	renderTimeViewClock,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
	HandleRequestProps,
	NetworkModal,
	NetworkCoinToken,
	SearchCriteria,
	TypeOfRequest,
	TypeOfTable,
} from "../../models/models";
import { api } from "../../utilities/index";
import { FixedSizeList } from "react-window";

export const OptionalSearchForm: React.FC<HandleRequestProps> = ({
	handleClick,
	browserTimeZone,
	timeZones,
	listOfNetworks,
	itemHeight,
	formData,
	onFileUpload,
}) => {
	const [protocol, setProtocol] = useState("");
	const [listOfTokens, setListOfTokens] = useState<NetworkCoinToken[]>([]);
	const [protocolToken, setProtocolToken] = useState<string | null>(
		listOfTokens.find((token: NetworkCoinToken) => {
			return token.contractAddress === formData.tokenContractAddresses?.[0];
		})?.name || ""
	);
	const [walletAddress, setWalletAdress] = useState(formData.walletAddress);
	const [blockNumber, setBlockNumber] = useState(formData.blockNumber || "");
	const [errorMsgForWalletAddress, setErrorMsgForWalletAddress] = useState("");
	const [errorProtocol, setErrorProtocol] = useState(false);
	const [timeZone, setTimeZone] = useState(formData.timeZone);

	const [dateAndTime, setDateAndTime] = useState<Dayjs | null>(
		formData.dateTime ? dayjs(formData.dateTime) : null
	);
	const [dateError, setDateError] = useState(false);
	const [isListOpen, setListOpen] = useState(false);
	const [searchWithoutDateOrBlokc, setSearchWithoutDateORBlock] =
		useState(false);
	const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
		coinAmount: 0,
		tokensAmount: 0,
	});
	const buttonLocation = useRef<HTMLDivElement | null>(null);

	const handleFileUpload = () => {
		if (onFileUpload) {
			onFileUpload(TypeOfTable.CustomizedBalances, false);
		}
	};

	useEffect(() => {
		setWalletAdress(formData.walletAddress);
		setDateAndTime(formData.dateTime ? dayjs(formData.dateTime) : null);
		setTimeZone(formData.timeZone);
		setBlockNumber(formData.blockNumber || "");
		if (
			formData.networkId &&
			Array.isArray(listOfNetworks) &&
			listOfNetworks.length > 0
		) {
			const selectedNetwork = listOfNetworks.find(
				// eslint-disable-next-line array-callback-return
				(network: NetworkModal) => {
					if (formData.networkId) {
						return network.id === +formData.networkId;
					}
				}
			);

			if (selectedNetwork) {
				setProtocol(selectedNetwork.name || "");
			}
		}
	}, [formData, listOfNetworks]);
	const handleInputValue = (e: any): void => {
		const value = e.target.value;
		const rexesArray: boolean[] = [];

		if (protocol.length === 0) {
			listOfNetworks?.map((network: NetworkModal): void => {
				const regex = new RegExp(network.walletValidationRegex);
				rexesArray.push(regex.test(value));
			});
		} else {
			const selectedProtocol = listOfNetworks?.find(
				(network: NetworkModal): boolean => {
					return network.name === protocol;
				}
			);
			const regex = new RegExp(
				selectedProtocol?.walletValidationRegex || ""
			);
			rexesArray.push(regex.test(value));
			if (selectedProtocol?.name === "Tron") {
				setSearchWithoutDateORBlock(regex.test(value));
			}
		}

		setWalletAdress(value);

		if (
			value.length === 0 ||
			!rexesArray.some((value: boolean): boolean => value === true)
		) {
			if (value.length === 0) {
				setErrorMsgForWalletAddress("The address value is required.");
			} else {
				setErrorMsgForWalletAddress(
					protocol.length > 0
						? `Invalid address format. Please provide a valid address for ${protocol}. `
						: "Invalid address format. Please provide a valid address."
				);
			}
		} else {
			setErrorMsgForWalletAddress("");
		}
	};
	useEffect(() => {
		if (buttonLocation.current) {
			const element =
				buttonLocation.current.getBoundingClientRect().top + window.scrollY;
			itemHeight(element);
		}
	}, [itemHeight]);

	useEffect(() => {
		if (formData.networkId) {
			getTokensForNetwork(+formData.networkId);
		}
	}, [formData]);
	useEffect(() => {
		if (formData.tokenContractAddresses) {
			const nameOfToken = listOfTokens.find((token: NetworkCoinToken) => {
				return (
					token.contractAddress === formData.tokenContractAddresses?.[0]
				);
			})?.name;
			setProtocolToken(nameOfToken ? nameOfToken : "");
		}
	}, [listOfTokens, formData]);

	const handleProtocolChange = (event: any) => {
		setProtocol(event.target.value);

		const selectedProtocol = listOfNetworks?.find((network: NetworkModal) => {
			return network.name === protocol;
		});
		setSearchCriteria({
			coinAmount: 1,
			tokensAmount: selectedProtocol?.assetsNumber || 0,
		});
		const regex = new RegExp(selectedProtocol?.walletValidationRegex || "");
		if (walletAddress.length !== 0 && !regex.test(walletAddress)) {
			setErrorMsgForWalletAddress(
				`Invalid address format. Please provide a valid address for ${protocol}. `
			);
		} else {
			setErrorMsgForWalletAddress("");
		}
		if (event.target.value.length === 0) {
			setErrorProtocol(true);
		} else {
			setErrorProtocol(false);
			const networkCoin = listOfNetworks?.find((item: NetworkModal) => {
				return item.name === event.target.value;
			});
			getTokensForNetwork(networkCoin?.id || 0);
		}
	};

	const handleBlockNumber = (event: any) => {
		setDateAndTime(null);
		const value = event.target.value.replace(/\D/g, "");
		event.target.value = value;
		setBlockNumber(event.target.value);
	};
	const handleTokenChange = (e: any) => {
		const value = e.target.value;

		const selectedProtocol = listOfNetworks?.find(
			(network: NetworkModal): boolean => {
				return network.name === protocol;
			}
		);
		if (value.length !== 0) {
			setSearchCriteria({
				coinAmount: 1,
				tokensAmount: 2,
			});
		} else {
			setSearchCriteria({
				coinAmount: 1,
				tokensAmount: selectedProtocol?.assetsNumber || 0,
			});
		}
		setProtocolToken(value);
	};

	const onTokenNetworkChange = useCallback(
		(e: any, newValue: any) => {
			setProtocolToken(newValue);
		},
		[setProtocolToken]
	);
	const sendRequest = () => {
		if (walletAddress.length === 0) {
			setErrorMsgForWalletAddress("The address value is required.");
		}
		if (protocol.length === 0) {
			setErrorProtocol(true);
		}

		if (walletAddress.length !== 0 && protocol.length !== 0) {
			if (!searchWithoutDateOrBlokc) {
				if (blockNumber?.length !== 0) {
					const networkId = listOfNetworks?.find(
						(network: NetworkModal) => network.name === protocol
					)?.id;
					const tokenAddersses = [
						listOfTokens.find((data: NetworkCoinToken) => {
							return data.name === protocolToken;
						})?.contractAddress || protocolToken!!,
					];

					const querryContractAddresses = tokenAddersses[0]
						? tokenAddersses
								.map((address: string, index: number) => {
									return `&assetsContractAddresses[${index}]=${address}`;
								})
								.join("")
						: "";

					handleClick(TypeOfRequest.OptionalSearch, {
						walletAddress: walletAddress,
						timeZone,
						blockNumber,
						searchCriteria: searchCriteria,
						networkId: networkId,
						tokenContractAddresses: tokenAddersses,
						requestQuerryParams: `?WalletAddress=${walletAddress}&TimeZone=${timeZone}&blockNumber=${blockNumber}&networkId=${networkId}${querryContractAddresses}`,
						isCustomContractAddress: listOfTokens.find(
							(data: NetworkCoinToken) => {
								return (
									data.name === protocolToken || protocolToken === ""
								);
							}
						)
							? false
							: true,
					});
				} else {
					const currentDate = dateAndTime?.format();
					const networkId = listOfNetworks?.find(
						(network: NetworkModal) => network.name === protocol
					)?.id;
					const tokenAddersses = [
						listOfTokens.find((data: NetworkCoinToken) => {
							return data.name === protocolToken;
						})?.contractAddress || protocolToken!!,
					];

					const querryContractAddresses = tokenAddersses[0]
						? tokenAddersses
								.map((address: string, index: number) => {
									return `&assetsContractAddresses[${index}]=${address}`;
								})
								.join("")
						: "";
					const dateForQp = currentDate
						? new Date(decodeURIComponent(currentDate.replace(/,/g, " ")))
								.toISOString()
								.slice(0, 19)
								.replace("T", " ")
						: "";
					handleClick(TypeOfRequest.OptionalSearch, {
						walletAddress: walletAddress,
						timeZone,
						dateTime: currentDate,
						searchCriteria: searchCriteria,
						networkId: networkId,
						tokenContractAddresses: tokenAddersses,
						requestQuerryParams: `?WalletAddress=${walletAddress}&TimeZone=${timeZone}${
							dateAndTime ? `&dateAndTime=${dateForQp}` : ""
						}&networkId=${networkId}${querryContractAddresses}`,
						isLatestReq: currentDate ? false : true,
						isCustomContractAddress: listOfTokens.find(
							(data: NetworkCoinToken) => {
								return (
									data.name === protocolToken || protocolToken === ""
								);
							}
						)
							? false
							: true,
					});
				}
			} else {
				const networkId = listOfNetworks?.find(
					(network: NetworkModal) => network.name === protocol
				)?.id;
				const tokenAddersses = [
					listOfTokens.find((data: NetworkCoinToken) => {
						return data.name === protocolToken;
					})?.contractAddress || protocolToken!!,
				];
				const querryContractAddresses = tokenAddersses[0]
					? tokenAddersses
							.map((address: string, index: number) => {
								return `&assetsContractAddresses[${index}]=${address}`;
							})
							.join("")
					: "";

				handleClick(TypeOfRequest.OptionalSearch, {
					walletAddress: walletAddress,
					timeZone,
					networkId: networkId,
					tokenContractAddresses: tokenAddersses,
					requestQuerryParams: `?WalletAddress=${walletAddress}&TimeZone=${timeZone}&networkId=${networkId}${querryContractAddresses}`,
					isLatestReq: true,
				});
			}
		}
	};
	const getTokensForNetwork = async (id: number) => {
		try {
			const response = await api.get(`get-assets?NetworkId=${id}`, {
				headers: {
					"Cache-Control": "public, max-age=3600",
				},
			});
			const { data } = response;
			setListOfTokens(data.assets);
		} catch (error: any) {
			console.log(error);
		}
	};

	const handleTimeZoneChange = (value: string) => {
		setTimeZone(value);
		setListOpen(false);
	};
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: 200,
				width: 200,
				minWidth: 200,
			},
		},
	};

	const ITEM_HEIGHT = 48;
	const VISIBLE_ITEMS = 400;

	const totalHeight = Math.min(timeZones.length, VISIBLE_ITEMS) * ITEM_HEIGHT;

	const renderRow = useCallback(({ index, style }: any) => {
		return (
			<MenuItem
				key={index}
				value={timeZones[index]}
				style={style}
				onClick={() => handleTimeZoneChange(timeZones[index])}
			>
				{timeZones[index]}
			</MenuItem>
		);
		// eslint-disable-next-line
	}, []);
	return (
		<Typography
			component="article"
			mt={0}
			sx={{
				maxWidth: "500px",
				width: { sm: "100%", md: "45%" },
				order: 5,
			}}
		>
			<Divider
				variant="inset"
				sx={{
					margin: "2rem 0 4rem",
					display: { xs: "block", sm: "block", md: "none", lg: "none" },
					width: "100%",
				}}
			/>
			<Typography
				component="h3"
				variant="h1"
				gutterBottom
				marginBottom={2}
				fontFamily={"Ysabeau SC"}
				id="specialized-balances-form"
			>
				Customized Balances
			</Typography>
			<Typography variant="subtitle2" gutterBottom>
				Use this form for the following cases:
			</Typography>
			<Typography variant="subtitle2" gutterBottom>
				Inquiry a network on a block number; for all assets; or for a given
				token contract;
			</Typography>
			<Typography variant="subtitle2" gutterBottom>
				Or, get a balance for a specific token; on a date, latest block or a
				block number.
			</Typography>{" "}
			<Box
				sx={{
					padding: " 0.7rem 0 13px ",
				}}
			>
				<form>
					<Stack spacing={3} width="100%">
						<FormControl
							error={errorProtocol}
							sx={{
								width: "100%",
								paddingBottom: errorProtocol ? 0 : "23px",
							}}
						>
							<InputLabel id="selectProtocol">
								Protocol Network *
							</InputLabel>
							<Select
								required
								label="Protocol Network *"
								labelId="Protocol Network"
								id="Protocol Network for customized Balance Form label"
								value={protocol}
								onChange={handleProtocolChange}
								onBlur={handleProtocolChange}
								error={errorProtocol}
								MenuProps={{
									MenuListProps: {
										style: { maxHeight: 290 },
									},
								}}
							>
								{listOfNetworks
									?.slice()
									.sort((a: NetworkModal, b: NetworkModal) => {
										return a.name.localeCompare(b.name);
									})
									.map((data: NetworkModal) => {
										return (
											<MenuItem
												value={data.name}
												key={data.name + `optional search`}
											>
												{data.name}
											</MenuItem>
										);
									})}
							</Select>
							{errorProtocol && (
								<FormHelperText sx={{ color: "#d32f2f" }}>
									The protocol network value is required.
								</FormHelperText>
							)}
						</FormControl>
						<TextField
							required
							autoComplete="off"
							id="wallet-address-customized-balances-form"
							label="Blockchain Wallet Address"
							variant="outlined"
							onChange={handleInputValue}
							onBlur={handleInputValue}
							error={errorMsgForWalletAddress.length !== 0}
							helperText={
								errorMsgForWalletAddress.length > 0
									? errorMsgForWalletAddress
									: false
							}
							value={walletAddress}
							sx={{
								paddingBottom:
									errorMsgForWalletAddress.length === 0 ? "23px" : 0,
							}}
						/>
						<Grid
							sx={{
								display: "flex",
								flexDirection: { xs: "column", md: "row" },
								gap: 0.5,
								margin: 0,
							}}
						>
							<Typography variant="h5" gutterBottom margin={0}>
								Optional:
							</Typography>
							<Typography variant="h5" gutterBottom margin={0}>
								Block, Date, Time & Timezone
							</Typography>
						</Grid>
						<FormControl
							sx={{
								width: { md: "300px", sm: "100%" },
							}}
						>
							<TextField
								autoComplete="off"
								id="optional-block-number-customized-balances-form"
								variant="outlined"
								label="Optional: Block Number"
								value={blockNumber}
								onChange={handleBlockNumber}
							/>
						</FormControl>

						<Box paddingBottom={5} sx={{ marginY: "50px" }}>
							<Typography variant="subtitle2">
								Leave the date blank to get the current balances (latest
								block).
								<br />
							</Typography>

							<Grid
								container
								justifyContent={"space-between"}
								rowSpacing={2}
								paddingTop={4}
								sx={{
									marginBottom: {
										xs: 0,
										md: dateError ? "55px" : "78px",
									},
								}}
							>
								<Grid item xs={12} md={6} key="date-input">
									<FormControl fullWidth>
										<LocalizationProvider dateAdapter={AdapterDayjs}>
											<DateTimePicker
												ampm={false}
												onError={(error) => {
													error === "invalidDate"
														? setDateError(true)
														: setDateError(false);
												}}
												slotProps={{
													textField: {
														helperText: dateError
															? " Please input a valid format."
															: false,
													},
												}}
												label="Select Date & Time"
												defaultValue={
													blockNumber?.length !== 0
														? null
														: dateAndTime
												}
												viewRenderers={{
													hours: renderTimeViewClock,
													minutes: renderTimeViewClock,
													seconds: renderTimeViewClock,
												}}
												onChange={(newValue: any) => {
													setBlockNumber("");
													setDateAndTime(newValue);
												}}
												value={dateAndTime}
											/>
										</LocalizationProvider>
									</FormControl>
								</Grid>

								<Grid item xs={12} md={5} key="input-timeZone">
									<FormControl fullWidth>
										<InputLabel id="timeZone">Time Zone</InputLabel>
										<Select
											onClose={() => setListOpen(false)}
											open={isListOpen}
											onOpen={() => setListOpen(true)}
											displayEmpty
											input={<OutlinedInput label="Time Zone" />}
											value={timeZone}
											renderValue={(selected) =>
												selected ? selected : timeZone
											}
											MenuProps={{
												...MenuProps,
												anchorOrigin: {
													vertical: "bottom",
													horizontal: "left",
												},
												transformOrigin: {
													vertical: "top",
													horizontal: "left",
												},
											}}
										>
											<FixedSizeList
												height={totalHeight}
												width={250}
												itemSize={ITEM_HEIGHT}
												itemCount={timeZones.length}
											>
												{renderRow}
											</FixedSizeList>
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Box>

						<Box
							sx={{
								marginTop: "16px",
								fontSize: 13,
							}}
						>
							<Typography variant="h5">
								Optional: Asset Contract Address
							</Typography>

							<FormControl
								fullWidth
								sx={{
									paddingTop: 3,
								}}
							>
								<Autocomplete
									options={listOfTokens
										.filter((asset: NetworkCoinToken) => {
											return asset.isNativeAsset === false;
										})
										.slice()
										.sort(
											(a: NetworkCoinToken, b: NetworkCoinToken) => {
												return a.name.localeCompare(b.name);
											}
										)
										.map((data: NetworkCoinToken) =>
											data.name ? data.name : ""
										)}
									freeSolo
									disabled={
										protocolToken?.length === 0
											? listOfTokens.filter(
													(asset: NetworkCoinToken) => {
														return asset.isNativeAsset === false;
													}
											  ).length === 0
												? true
												: false
											: false
									}
									value={protocolToken}
									onChange={onTokenNetworkChange}
									renderInput={(params) => (
										<TextField
											onChange={handleTokenChange}
											onSelect={handleTokenChange}
											{...params}
											label="Input address or select from our list of assets for network"
											variant="outlined"
											fullWidth
											helperText={
												protocolToken?.length === 0
													? protocol.length === 0
														? "Please choose a protocol from the list of protocols located at the top of the form."
														: listOfTokens.length === 1
														? "The selected protocol has no assets."
														: " "
													: " "
											}
										/>
									)}
								/>
							</FormControl>
						</Box>
					</Stack>
				</form>

				<Grid
					sx={{
						display: "flex",
						flexDirection: { xs: "column", md: "row" },
						justifyContent: { xs: "center", md: "space-between" },
						alignItems: "flex-start",
					}}
				>
					<Button
						sx={{
							fontSize: 13,
							marginTop: 2,
							minWidth: "160px",
						}}
						variant="contained"
						onClick={sendRequest}
						size="large"
						disabled={
							errorMsgForWalletAddress.length !== 0 || errorProtocol
						}
					>
						Get Balances
					</Button>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
							gap: 0.25,
						}}
					>
						{" "}
						<Button
							sx={{
								fontSize: 13,
								marginTop: 2,
								minWidth: "160px",
							}}
							variant="contained"
							onClick={handleFileUpload}
							size="large"
						>
							Upload file
						</Button>{" "}
						<Typography variant="subtitle2">
							{" "}
							for batch balances
						</Typography>
					</Box>
				</Grid>
			</Box>{" "}
			<div ref={buttonLocation}></div>
		</Typography>
	);
};
