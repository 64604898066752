import { createTheme } from "@mui/material/styles";

export const Theme = createTheme({
	typography: {
		h1: {
			fontSize: "2rem",
			"@media (min-width:600px)": {
				fontSize: "2.5rem",
			},
			fontWeight: 400,
			lineHeight: 1.2,
			fontFamily: "Ysabeau SC",
		},

		h4: {
			fontSize: "1.5rem",
			"@media (min-width:600px)": {
				fontSize: "1.6rem",
			},
			lineHeight: 1.7,
		},
		h5: {
			fontSize: "1.2rem",
		},
		h6: {
			fontSize: "1rem",
			lineHeight: 1.5,
		},

		body1: {
			fontSize: "1.1rem",
			lineHeight: 1.7,
		},
		body2: {
			fontSize: "0.875rem",
			"@media (min-width:600px)": {
				fontSize: "1.2rem",
			},
		},
		subtitle1: {
			fontSize: "0.875rem",
		},
		subtitle2: {
			fontSize: "1rem",
			color: "#757575",
		},
	},
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 1000,
			lg: 1200,
			xl: 1536,
		},
	},

	palette: {
		primary: {
			main: "#0850CF",
		},
		error: {
			main: "#cf351d",
		},
		text: {
			primary: "#5A5A5A",
			secondary: "#5A5A5A",
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					fontFamily: "Ysabeau SC",
				},
			},
		},
	},
});
